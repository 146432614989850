import {combineReducers} from 'redux'
import {reducer as form} from 'redux-form'
import {uiReducer} from "../reducers/ui/ui.reducer"
import {linksReducer} from "../reducers/links/links.reducer";
import {initReducer} from "../reducers/init/init.reducer";
import {tarifReducer} from "../reducers/tarif/tarif.reducer";
import {saveDevisReducer} from "../reducers/save_devis/save_devis.reducer";
import {contractReducer} from "../reducers/contract/contract.reducer";

const AppReducer = combineReducers({
    form,
    ui: uiReducer,
    links: linksReducer,
    init: initReducer,
    tarif: tarifReducer,
    devis: saveDevisReducer,
    contract: contractReducer

})

export default AppReducer
