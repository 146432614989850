import {FETCH_LINKS, LINKS, setLinks} from '../../../actions/app/links/links.actions'
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'

export const linksMiddleware = () => next => action => {
    next(action)

    switch (action.type){

        case FETCH_LINKS:

            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: LINKS}),
                setLoader({state: true, entity: LINKS})
            ])
            break

        case `${LINKS} ${API_SUCCESS}`:
            next([
                setLinks({links: action.payload.data}),
                setError({state: false, entity: LINKS}),
                setLoader({state: false, entity: LINKS})
            ])
            break

        case `${LINKS} ${API_ERROR}`:
            next([
                setError({state: true, entity: LINKS}),
                setLoader({state: false, entity: LINKS})
            ])
            break

        default:
            return null
    }
}
