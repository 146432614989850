export const CONTRACT = '[Contract]'

export const SET_CONTRACT = `${CONTRACT} Set`

export const setContract = ({data}) => ({
    type: SET_CONTRACT,
    payload: {
        data
    }
})
