import React from 'react';
import './tips.scss'

const Tips = (props) => {

    const { children, size } = props

    return (
        <span className={`tips ${size}`}>
            <span>{children}</span>
        </span>
    );
};

Tips.defaultProps = {
    size: 'small',
}

export default Tips;
