import {createSelector} from 'reselect'

/* Init */
const devisSelector = state => state.devis

export const getUrlPDFDevis = createSelector(
    devisSelector,
    devis => devis.UrlPDFDevis
)

export const getUrlPDFFicheConseil = createSelector(
    devisSelector,
    devis => devis.UrlPDFFicheConseil
)

export const getIDDevis = createSelector(
    devisSelector,
    devis => devis.IDDevis
)

