import {API_URL_WS_FORMULAIRE} from '@amo/core/constants';

export const SAVE_DEVIS = '[Save devis]'

export const SET_SAVE_DEVIS = `${SAVE_DEVIS} Set`
export const POST_SAVE_DEVIS = `${SAVE_DEVIS} Post`

export const postSaveDevis = ({form, token}) => ({
    type: POST_SAVE_DEVIS,
    payload: {
        data: `${API_URL_WS_FORMULAIRE}/save_devis/${token}`,
        body: form,
    }
})

export const setSaveDevis = ({saveDevis}) => ({
    type: SET_SAVE_DEVIS,
    payload: {
        data: saveDevis
    }
})
