import {API_URL_WS_FORMULAIRE} from '@amo/core/constants';

export const CHECK_STATUS = '[Check status]'

export const FETCH_CHECK_STATUS = `${CHECK_STATUS} Fetch`

export const fetchStatus = ({token, body}) => ({
    type: FETCH_CHECK_STATUS,
    payload: {
        data: `${API_URL_WS_FORMULAIRE}/init`,
        body: {token, ...body},
    }
})
