import _ from 'lodash'
import moment from "moment";

/**
 * creer un clone du formulaire pour y faire des modifications de champs pour l'API
 * @param body
 * @returns {*}
 */
export const formatDataForApi = body => {
    let cloneBody = _.cloneDeep(body);

    stringToBool(cloneBody);
    stringToInt(cloneBody);
    dateToString(cloneBody);
    removePermisNull(cloneBody);
    removeSpace(cloneBody);
    removeHyphen(cloneBody);
    reIndexArray(cloneBody);
    dateHourToString(cloneBody);

    return cloneBody
};

/**
 * retire les espaces dans les champs
 * @param clone
 */
const removeSpace = clone => {
    const fields = [
        'DemandeTarif[ListePersonnes][0][TelPortable]',
        'DemandeContrat[SignaturePhone]'
    ]

    _.forEach(fields, f => {
        _.get(clone, f) && _.set(clone, f, _.get(clone, f).replace(/\s/g, ''))
    })
}

/**
 * retire les tirets dans les champs
 * @param clone
 */
const removeHyphen = clone => {
    const fields = [
        'DemandeTarif[Vehicule][Immatriculation]',
        'DemandeContrat[IBAN]'
    ]

    _.forEach(fields, f => {
        _.get(clone, f) && _.set(clone, f, _.get(clone, f).replaceAll('-', ''))
    })
}

/**
 * convertie les champs qui ont 'true' ou 'false' en boolean
 * @param clone
 */
const stringToBool = clone => {
    const booleans = [
        'DemandeTarif[ListePersonnes][0][DeclaASSR2]',
    ];


    const returnBool = string => string === 'true' || string === '1';


    _.forEach(booleans, b => {
        _.get(clone, b) && _.set(clone, b, returnBool(_.get(clone, b)))
    })
}

/**
 * convertie les champs string en integer
 * @param clone
 */
const stringToInt = clone => {
    const strings = [
    ];

    _.forEach(strings, s => {
        _.get(clone, s) && _.set(clone, s, Number(_.get(clone, s)))
    })
}

/**
 * convertie les champs de date moment en string dd/mm/aaaa
 * @param clone
 */
const dateToString = clone => {
    const dates = [
        'DemandeTarif[ListePersonnes][0][DateNaissance]',
        'DemandeContrat[TiersPayeur][BirthDate]'
    ];

    _.forEach(dates, d => {
        _.get(clone, d) && _.set(clone, d, moment(_.get(clone, d)).format('L'))
    })
}

/**
 * convertie les champs de date moment en string dd/mm/aaaa hh:mm
 * @param clone
 */
const dateHourToString = clone => {
    const dates = [
        'DemandeContrat[DateHeureEffet]'
    ];

    _.forEach(dates, d => {
        _.get(clone, d) && _.set(clone, d, moment(_.get(clone, d)).format('DD/MM/YYYY HH:mm'))
    })
}

/**
 * Retire les permis qui ont un TypePermis à null
 * @param clone
 */
const removePermisNull = clone => {
    const conducteur = clone.DemandeTarif.ListePersonnes;

    conducteur && conducteur[0] && _.remove(conducteur[0].ListePermis, p => {
        return p && (p.TypePermis === null || p.TypePermis === undefined)
    })
};

/**
 * Réindex les array qui ont un index undefined
 * @param clone
 */
const reIndexArray = clone => {
    const arrays = [

    ]

    _.forEach(arrays, a => {
        _.get(clone, a) && _.set(clone, a, _.filter(_.get(clone, a), item => item !== undefined))
    })
}
