import React, {useEffect, useState} from 'react';
import _ from "lodash";
import {SmallSpinner} from "@amo/core/components/loaders";
import {Title} from "@amo/core/components/containers";

const PaiementEtapes = (props) => {
    const {id_devis, etapes_paiement, onSubmitCheckStatus, contratState} = props
    const [secondesAvtRefresh, setSecondesAvtRefresh] = useState(60);

    useEffect(() => {
        let interval = null;

        if (secondesAvtRefresh>0) {
            interval = setInterval(() => {
                setSecondesAvtRefresh(seconds => seconds - 1);
            }, 1000);
        } else{
            onSubmitCheckStatus()
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [secondesAvtRefresh]);

    return (
        <div className={'steps-formulaire'}>
            <div className={'container'}>
                <div className={'row'}>
                    <div className={'col'}>
                        <Title>Devis {id_devis} - Contrat en { contratState === 'contrat_en_attente_de_paiement' ? 'attente de paiement' : 'cours d\'enregistrement'}...</Title>

                        {
                            contratState === 'contrat_en_attente_de_paiement' &&
                            <strong className={'f-21'}>Attention, le contrat ne sera pas validé si le paiement n'est pas finalisé dans les 48h.</strong>
                        }
                    </div>
                </div>
                {
                    contratState === 'contrat_en_attente_de_paiement' &&
                    <div className={'row'}>
                        <div className={'col'}>
                            <div className={'mt-5 mb-5'}>
                                {
                                    etapes_paiement && _.map(etapes_paiement, (etape, index) => (
                                        <div key={index} className={'d-flex py-3 border-top align-items-center'}>
                                            { etape.date ?
                                                <span className={`icon-Shape text-primary`} style={{fontSize: 16}} /> : <SmallSpinner/> }
                                            <span className={'d-inline-block ml-4'}>{etape.name}</span>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                }

                <div className={'row'}>
                    <div className={'col text-center'}>
                        <button className={'btn btn-primary medium-rounded text-uppercase mb-2'} onClick={onSubmitCheckStatus} >
                            {secondesAvtRefresh>0 ? `Actualiser (${secondesAvtRefresh})` : 'Actualisation...'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default PaiementEtapes;
