import {
    SET_LOADER,
    SET_MAINTENANCE,
    SET_ERROR,
    SET_NEXT_PAGE,
    SET_PREVIOUS_PAGE,
    SET_MAX_PAGE, SET_STEP, SET_FORMULE, SET_GO_TO_PAY, SET_UPLOAD, SET_COUNTRIES
} from '../../actions/app/ui/ui.actions'


const initState = {
    maintenance: false,
    step: 0,
    maxStep: 0,
    goToPay: false,
    countries: []
}

export const uiReducer = (ui = initState, action) => {
    const { payload, meta } = action

    switch (true){

        case action.type.includes(SET_LOADER):
            return {...ui, loading: {...ui.loading, [meta]: payload}}

        case action.type.includes(SET_ERROR):
            return {...ui, error: {...ui.error, [meta]: payload}}

        case action.type.includes(SET_MAINTENANCE):
            return{...ui, maintenance : payload}

        case action.type.includes(SET_NEXT_PAGE):
            return{...ui, step : payload.step}

        case action.type.includes(SET_MAX_PAGE):
            return{...ui, maxStep : payload}

        case action.type.includes(SET_PREVIOUS_PAGE):
            return{...ui, step : payload}

        case action.type.includes(SET_STEP):
            return {...ui, step: payload}

        case action.type.includes(SET_FORMULE):
            return {...ui, formule: payload}

        case action.type.includes(SET_GO_TO_PAY):
            return {...ui, goToPay: payload}

        case action.type.includes(SET_UPLOAD):
            return {...ui, upload: {...ui.upload, [action.payload.formName]: action.payload.value}}

        case action.type.includes(SET_COUNTRIES):
            return {...ui, countries: payload}

        default:
            return ui
    }
}
