import React, {Component} from 'react'
import { Title } from '@amo/core/components/containers'
import _ from "lodash";

class RetourSignature extends Component {

    render(){
        const { signature, listDocument, emailApporteur, source, documentsUrl, isAvenant, pdfAttestation, pdfContrat, pdfSepa } = this.props

        return (
            <div className={'steps-formulaire'}>
                <div className={'container'}>
                    <div className={'row'}>
                        <div className={'col'}>
                            <div className={'bg-secondary-light p-4 mb-5 text-center rounded'}>
                                <div className={'f-21 text-primary-light mb-3'}>
                                    <span className={`icon-trottinette d-inline-block position-relative mr-2`} style={{fontSize: 30}} />
                                    {source === 'site' ? 'Bienvenue chez APRIL Moto ! Merci de votre confiance.' : 'Merci de votre confiance.'}
                                </div>
                                <div className={'f-18 mb-3'}>
                                    {source === 'site' ? 'Félicitations, vous êtes désormais assuré(e) lors de vos déplacements en trottinette électrique !' : 'Votre client est désormais assuré(e) lors de ses déplacements en trottinette électrique !'}
                                </div>
                                {signature && signature.signed && <div className={'f-18 mb-3'}>
                                    Vos documents signés vont vous être envoyés par email
                                </div>}

                                {
                                    source === 'courtier' && !isAvenant &&
                                    <>
                                        {
                                            pdfAttestation && <div>
                                                <a target="_blank" rel="noopener noreferrer" href={pdfAttestation} className={'btn btn-primary medium-rounded text-uppercase mb-2'}>Télécharger son attestation d'assurance</a>
                                            </div>
                                        }
                                        {
                                            pdfContrat && <div>
                                                <a target="_blank" rel="noopener noreferrer" href={pdfContrat} className={'btn btn-primary medium-rounded text-uppercase mb-2'}>Télécharger son contrat</a>
                                            </div>
                                        }
                                        {
                                            pdfSepa && <div>
                                                <a target="_blank" rel="noopener noreferrer" href={pdfSepa} className={'btn btn-primary medium-rounded text-uppercase mb-2'}>Télécharger son mandat SEPA</a>
                                            </div>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    </div>

                    <div className={'row'}>
                        <div className={'col'}>
                            <Title icon={'document'}>Envoyez nous vos documents</Title>
                            <div>
                                <div>
                                    <ul className={'mt-3'}>
                                        {
                                            _.map(listDocument, (d, i) => (
                                                <li key={i} className={'p-1'}>{d.Description}</li>
                                            ))
                                        }
                                    </ul>
                                </div>

                                <div className={'f-16 mt-4'}>
                                    <p className={"mb-4"}>Vous n'avez pas vos pièces justificatives sur vous ? {!isAvenant ? `Pas de panique, vous
                                        recevrez un email pour activer votre espace personnel et nous envoyez vos
                                        documents.` : `Envoyez vos documents`}</p>
                                    <strong>• Dès maintenant en ligne</strong>

                                    <div className={'mb-3'}>
                                        <a href={documentsUrl} className={'btn btn-primary medium-rounded text-uppercase mb-2'}>Envoyer vos documents</a>
                                    </div>

                                    <p className={"mb-5"}>Vous pouvez nous transmettre vos documents au format: doc, jpg, png, pdf</p>
                                    <strong>• Par email à l'adresse</strong>
                                    <p>{source === 'site' ? 'moncontrat@april-moto.com' : emailApporteur}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default RetourSignature
