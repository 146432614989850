import {connect} from 'react-redux';
import Informations from './Informations';
import {bindActionCreators, compose} from "redux";
import {formValueSelector, reduxForm, change, getFormValues} from "redux-form";
import validate from './validate'
import {
    getEmailDPOWhitelabel, getNameWhitelabel,
    getSource,
    getSouscripteurDefine,
    getToken, getUrlSiteWhitelabel,
    getGestionnaireInit, getActeGestionDemandeTarifInit
} from "../../../../../redux/selectors/init/init.selectors";
import {postSaveDevis, SAVE_DEVIS} from "../../../../../redux/actions/app/save_devis/save_devis.actions";
import contenu from '../../../../../constants/contenu_text'
import {
    getGoToPay, getLibelleFormuleChoice, getListCountries,
    getLoadingEntity
} from "../../../../../redux/selectors/ui/ui.selectors";
import {setCountries} from "../../../../../redux/actions/app/ui/ui.actions";

const mapStateToProps = state => {
    const selector = formValueSelector('devis')
    const ville = selector(state, 'DemandeTarif[ListePersonnes][0][Ville]')
    const cp = selector(state, 'DemandeTarif[ListePersonnes][0][CP]')

    return {
        data: getFormValues('devis')(state),
        postalCodeCity: ville ? `${ville} ${cp}` : '',
        formule: selector(state, 'DemandeTarif[Police][FormuleChoisie]'),
        isAvenant: getActeGestionDemandeTarifInit(state) === 'AV',
        pcc: selector(state, 'DemandeTarif[ListeOptions][0][Souscrite]'),
        souscripteurIsDefined: getSouscripteurDefine(state),
        source: getSource(state),
        goToPay: getGoToPay(state),
        loading: getLoadingEntity(state, SAVE_DEVIS),
        nomConducteur: selector(state, 'DemandeTarif[ListePersonnes][0][Nom]'),
        prenomConducteur: selector(state, 'DemandeTarif[ListePersonnes][0][Prenom]'),
        cpConducteur: selector(state, 'DemandeTarif[ListePersonnes][0][CP]'),
        villeConducteur: selector(state, 'DemandeTarif[ListePersonnes][0][Ville]'),
        cpIBAN: selector(state, 'DemandeContrat[cpIBAN]'),
        villeIBAN: selector(state, 'DemandeContrat[villeIBAN]'),
        contenuText : contenu[getSource(state)],
        libelle: getLibelleFormuleChoice(state),
        whitelabelName: getNameWhitelabel(state),
        emailDPOWhitelabel: getEmailDPOWhitelabel(state),
        urlSiteWhitelabel : getUrlSiteWhitelabel(state),
        gestionnaireIsAMO: getGestionnaireInit(state).codeBelair === '43397',
        ibanIsCheck: Boolean(selector(state, 'DemandeContrat[BIC]')),
        ibanValue:selector(state, 'DemandeContrat[IBAN]'),
        addressNotFound:selector(state, 'addressNotFound'),
        token: getToken(state),
        adresseNew: selector(state, 'DemandeTarif[ListePersonnes][0][Adresse1]'),
        email: selector(state, 'DemandeTarif[ListePersonnes][0][Email]'),
        emailValidation: selector(state, 'EmailValidation'),
        emailOnError: selector(state, 'emailOnError'),
        countries: getListCountries(state),
        birthCity: selector(state, 'DemandeTarif[ListePersonnes][0][BirthCity]'),
        birthCountry: selector(state, 'DemandeTarif[ListePersonnes][0][BirthCountry]'),
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    changeValue: (field, value) => change('devis', field, value),
    onSubmit: (form, token) => postSaveDevis({form, token}),
    setCountries
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { onSubmit } = dispatchProps
    const { token } = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: (form) => {
            onSubmit(form, token)
        }
    }
}

const InformationsContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'devis',
        destroyOnUnmount: false,
        validate
    })
)(Informations)

export default InformationsContainer
