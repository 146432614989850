import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setGoToPay, setLoader, setStep} from '../../../actions/app/ui/ui.actions'
import {POST_SAVE_DEVIS, SAVE_DEVIS, setSaveDevis} from "../../../actions/app/save_devis/save_devis.actions";
import {setFieldForUselessAPI} from "./script.js"
import _ from "lodash";
import moment from 'moment'
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import {analyticsSetEvent} from "../../../actions/core/analytics/analytics.actions.js";

export const saveDevisMiddleware = ({dispatch}) => next => action => {
    next(action)

    const { payload } = action

    switch (action.type){

        case POST_SAVE_DEVIS:

            const body = setFieldForUselessAPI(payload.body)

            _.set(body, 'DemandeTarif[ListePersonnes][0][DeclaASSR2]', (_.get(body, 'DemandeTarif[ListePersonnes][0][DeclaASSR2]') === "true"))
            _.set(body, 'DemandeTarif[ListePersonnes][0][DateNaissance]' , moment(_.get(body, 'DemandeTarif[ListePersonnes][0][DateNaissance]'), 'DD-MM-YYYY').format('L'))
            if(_.get(body, 'DemandeContrat[TiersPayeur][BirthDate]')) {
                _.set(body, 'DemandeContrat[TiersPayeur][BirthDate]' , moment(_.get(body, 'DemandeContrat[TiersPayeur][BirthDate]'), 'DD-MM-YYYY').format('L'))
            }

            if (!moment.isMoment(_.get(body, 'DemandeContrat[DateHeureEffet]'))){
                _.set(body, 'DemandeContrat[DateHeureEffet]', moment())
            }

            _.set(body, 'DemandeContrat[DateHeureEffet]' , moment(_.get(body, 'DemandeContrat[DateHeureEffet]')).format('DD/MM/YYYY HH:mm'))

            next([
                apiRequest({body, method: 'POST', url: payload.data, entity: SAVE_DEVIS, otherData: body.destination}),
                setLoader({state: true, entity: SAVE_DEVIS})
            ])
            break

        case `${SAVE_DEVIS} ${API_SUCCESS}`:
            const nextActions = [
                setSaveDevis({saveDevis: payload.data.data.Devis}),
                setError({state: false, entity: SAVE_DEVIS}),
                setLoader({state: false, entity: SAVE_DEVIS})
            ]

            if (payload.meta.otherData === 'proposition'){
                /* Save devis étape information pour avoir le devis */
                nextActions.push(analyticsSetEvent({event: 'emailQuoteConfirmation', ap1: payload.data?.data?.DemandeTarif?.Police?.IdApporteur1}));
                nextActions.push(setStep({step: 4}))
            } else if ((payload.meta.otherData === 'souscription')) {
                /* Save devis étape Récapitulatif pour aller payer */
                nextActions.push(analyticsSetEvent({event: 'emailQuote', ap1: payload.data?.data?.DemandeTarif?.Police?.IdApporteur1}));
                nextActions.push(setGoToPay({state: true}))
            } else {
                /* Save devis étape information pour souscrire */
                window.scroll(0,0)
                nextActions.push(setStep({step: 3}))
            }

            next(nextActions)

            break

        case `${SAVE_DEVIS} ${API_ERROR}`:
            next([
                setNotification({entity: SAVE_DEVIS, title: 'Attention', confirmButtonText: 'Continuer', icon: 'error', text: payload.data.response.data.error}),
                setError({state: true, entity: SAVE_DEVIS}),
                setLoader({state: false, entity: SAVE_DEVIS})
            ])
            break

        default:
            return null
    }
}
