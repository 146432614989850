import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {change, clearFields, formValueSelector, reduxForm} from "redux-form";
import { DemandeRappel } from '@amo/core/components/group-form';
import {POST_DEMANDE_RAPPEL, postDemandeRappel} from "../../../../../redux/actions/app/demandeRappel/demandeRappel.actions.js";
import {API_URL_WS_FORMULAIRE} from '@amo/core/constants';
import {getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors.js";

const mapStateToProps = state => {
    const selector = formValueSelector('demande-rappel')
    return {
        query: `${API_URL_WS_FORMULAIRE}/besoin-d-aide`,
        asap: selector(state, 'asap'),
        postDemandeLoading: getLoadingEntity(state, POST_DEMANDE_RAPPEL),
        initialValues: {
            asap: false
        }
    }
}


const mapDispatchToProps = dispatch => bindActionCreators({
    changeValue: (field, value) => change('demande-rappel', field, value),
    unsetValue: (field) => clearFields('demande-rappel', false, false, field),
    postDemandeRappel
}, dispatch)


const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {query} = stateProps
    const {postDemandeRappel} = dispatchProps


    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        postDemandeRappel: (form) => postDemandeRappel({query:query, form}),
    }
}


const DemandeRappelContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'demande-rappel'
    })
)(DemandeRappel)


export default DemandeRappelContainer
