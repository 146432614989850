import {createSelector} from 'reselect'
import _ from "lodash";

/* Tarif */
const tarifSelector = state => state.tarif
/* Liste garanties par formule */
const formuleSelectedSelector = (state, code) => _.find(state.tarif.ListeFormules, ['CodeFormule', code]) || {}

export const getUrlPDFDispositionsGenerales = createSelector(
    tarifSelector,
    tarif => tarif.UrlPDFDispositionsGenerales
)

export const getUrlPDFDocumentInformation = createSelector(
    tarifSelector,
    tarif => tarif.UrlPDFDocumentInformation
)

/* Formule Preconisee */
export const getCodeFormulePreconiseeTarif = createSelector(
    tarifSelector,
    tarif => tarif.FormulePreconisee
)

/* Liste Formules */
export const getListeFormule = createSelector(
    tarifSelector,
    tarif => tarif.ListeFormules
)

/* Formule */
export const getFormuleF1 = createSelector(
    getListeFormule,
    formules => _.find(formules, ['CodeFormule', 'F1'])
)

export const getFormuleF2 = createSelector(
    getListeFormule,
    formules => _.find(formules, ['CodeFormule', 'F2'])
)

export const getCodeProduit = createSelector(
    tarifSelector,
    tarif => tarif.CodeProduit
)

export const getPoliceTarif = createSelector(
    tarifSelector,
    tarif => tarif.Police || {}
)

export const getHonorairesMaxiApporteurTarif = createSelector(
    getPoliceTarif,
    police => police.HonorairesMaxi ? police.HonorairesMaxi : 20
)

export const getTauxApporteurMaxiApporteurTarif = createSelector(
    getPoliceTarif,
    police => police.TauxApporteur1Maxi ? police.TauxApporteur1Maxi : 20
)

export const getGarantiesOption = createSelector(
    formuleSelectedSelector,
    formule => _.orderBy(_.filter(formule.ListeGaranties, { TypeGarantie: 'Option', Disponible: 'Option' }), ['OrdreAffichage'], ['asc'])
)


/* CODE PROMO */
export const getInfoCodePromo = createSelector(
    tarifSelector,
    tarif => tarif.CodePromo
)
/* Liste Formules */
export const getListeFormulesTarif = createSelector(
    tarifSelector,
    tarif => _.orderBy(tarif.ListeFormules, ['CodeFormule'], ['asc']) || []
)