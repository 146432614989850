import {Component} from 'react';
import PropTypes from 'prop-types'
import { Title } from '@amo/core/components/containers'

class AnnulationPaiement extends Component {
    render(){
        const {url_pay, myNewDevis, source} = this.props

        return (
            <div className={'steps-formulaire'}>
                <div className={'container'}>
                    <div className={'row'}>
                        <div className={'col text-center'}>
                            <Title>Oups !</Title>
                            <p>Il semble qu'il y ait eu un souci au niveau du paiement, que souhaitez-vous faire ?</p>

                            <div className={'mt-5 text-center'}>
                                {
                                    source === 'courtier' ?
                                        <button className={'btn btn-outline-primary d-none d-lg-inline-block'}
                                                onClick={() => window.location = 'https://www.april-on.fr/'}
                                        >
                                            Je recommence un devis depuis APRIL On
                                        </button>
                                        : source === 'site' ? <button className={'btn btn-outline-primary d-none d-lg-inline-block'}
                                                  onClick={myNewDevis}
                                        >
                                            Je recommence un devis
                                        </button> : null
                                }

                                { url_pay.uri && <form action={url_pay.uri} method={'POST'} style={{display: 'inline-block', margin: 15}}>
                                    <input type="text" name='token' defaultValue={url_pay.token} hidden />
                                    <input type="text" name='url_return' defaultValue={url_pay.url_return} hidden />
                                    <button className={'btn btn-block btn-tertiary medium-rounded text-uppercase'}>
                                        Je finalise la souscription
                                    </button>
                                </form> }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

AnnulationPaiement.propTypes = {
    url_pay: PropTypes.object,
    myNewDevis: PropTypes.func
}

export default AnnulationPaiement;
