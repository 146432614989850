import React from 'react';
import FormulaireContainer from './Formulaire/FormulaireContainer'

const Init = (props) => {
    const { erreur } = props

    if (erreur){
        window.location = '/'
        return null
    }

    return (
        <FormulaireContainer />
    )
}

export default Init
