import _ from "lodash";

const routeStep = [
    {
        step: 0,
        title: 'Vos besoins',
    },
    {
        step: 1,
        title: 'Votre tarif',
    },
    {
        step: 2,
        title: 'Vos informations',
    },
    {
        step: 3,
        title: 'Votre récapitulatif',
    },
    {
        step: 4,
        title: 'Proposition d\'assurance',
    },
    {
        step: 4.5,
        title: 'Paiement',
    },
    {
        step: 5,
        title: 'Signature',
    },
    {
        step: 6,
        title: 'Envoi des pièces',
    },
    {
        step: 7,
        title: 'Reprise',
    },
    {
        step: 8,
        title: 'Validation',
    }
];


export const getTitleByStep = (step) => {
    const route = _.find(routeStep, ['step', step]);
    return route && route !== -1 ? route.title : '';
};