import React, {Component} from 'react';
import moment from 'moment'
import {Field} from "redux-form";
import _ from 'lodash'
import { Radio, Text, DateField, PostalCodeCity} from '@amo/core/components/forms'
import {maxDate30Days, required} from '@amo/core/utils/validateField.js';
import SidebarContainer from "./Sidebar/SidebarContainer";
import ScrollingTable from "./ScrollingTable/ScrollingTable";
import TiersPayeurContainer from "./TiersPayeur/TiersPayeurContainer";
import {SmallSpinner} from "@amo/core/components/loaders";
import { Title, QuestionLabel } from '@amo/core/components/containers'
import {checkIban} from "@amo/core/utils";

class Recapitulatif extends Component {
    constructor (props) {
        super(props)
        this.state = {
            showMore: false,
            goToPay: false
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.cpConducteur !== prevProps.cpConducteur) {
            this.props.changeValue('DemandeContrat[cpIBAN]', this.props.cpConducteur)
        }
        if (this.props.villeConducteur !== prevProps.villeConducteur) {
            this.props.changeValue('DemandeContrat[villeIBAN]', this.props.villeConducteur)
        }
    }

    componentDidMount() {
        this.props.changeValue('DemandeContrat[DateHeureEffet]', moment())
    }

    render(){
        const { postalCodeCity, ibanIsCheck, goToPay, previousPage, formule, url_pay, isAvenant, source, changeValue, handleSubmit, loading, contenuText, invalid, submitting, listeFormules, prixTotalAnnuel, nomTitulaire, nomPrenom, analyticsSetEvent } = this.props

        const fetchCheckIban = async (value) => {
            this.setState({
                loadingIban: true
            })
            let iban = await checkIban(value)
            this.setState({
                loadingIban: false
            })
            changeValue('DemandeContrat[BIC]', iban.bank_data.bic)

            /* Vérifie si le format de l'iban est correct */
            const codeValidationsSuccess = ['001', '002', '003', '004', '005', '006', '007'];
            const fieldValidations = ['account', 'country_support', 'iban', 'length', 'structure'];
            let error = null

            _.forEach(fieldValidations, field => {
                if (!_.includes(codeValidationsSuccess, iban.validations[field].code)){
                    error = iban.validations[field].message
                    return false;
                }
            });

            /* Vérifie si l'iban accepte les prélèvement */
            if (iban.sepa_data.SDD === "NO" || iban.sepa_data.SCT === "NO"){
                return this.setState({
                    errorIban: "L'IBAN renseignée n'autorise pas les prélèvements bancaires"
                })
            }

            return error ? this.setState({errorIban: `Le format de l'IBAN n'est pas valide. <br /> ${error}`}) : this.setState({errorIban: null})
        }

        if (goToPay){
            if (!this.state.goToPay){
                this.buttonElement.click()
                this.setState({
                    goToPay
                })
            }
        }

        const changeLienPaiement = value => {
            if(value === 'true'){
                changeValue('DemandeContrat[ModePaiementComptant]', 'M')
            } else {
                changeValue('DemandeContrat[ModePaiementComptant]', null)
            }
        }

        const verificationTitulaire = value => {
            if(value && _.toUpper(nomPrenom) === _.toUpper(value)){
                changeValue('DemandeContrat[TiersPayeur]', {})
            }
        }

        return (
            <div className={'container steps-formulaire'}>
                <form className={'row'}>
                    <div className={'col-lg-8'} id={'recapitulatif'}>
                        <Title icon={'work'} dangerouslySetInnerHTML={{__html: 'Récapitulatif' }} />

                        <div className={'row my-4'}>
                            <div className={'col-12 col-md-8'}>
                                <ScrollingTable formule={_.find(listeFormules, ['CodeFormule', formule] )} prixTotal={prixTotalAnnuel} />
                            </div>
                        </div>

                        {
                            !isAvenant && <>
                                <QuestionLabel className={'f-16 mt-4'}>Nom et prénom du titulaire du compte<sup className={'text-danger'}>*</sup></QuestionLabel>
                                <div className={'row'}>
                                    <div className={'col-12 col-md-6 mt-3 mt-md-4'}>
                                        <Field
                                            component={Text}
                                            name={'DemandeContrat[NomTitulaire]'}
                                            normalize={v => v.toUpperCase()}
                                            validate={required}
                                            onChange={e => verificationTitulaire(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <QuestionLabel className={'f-16 mt-4'}>Adresse&nbsp;<sup className={'text-danger'}>*</sup></QuestionLabel>
                                <div className={'row'}>
                                    <div className={'col-12 col-md-6 mt-3 mt-md-4'}>
                                        <Field
                                            component={Text}
                                            name={'DemandeContrat[adrIBAN]'}
                                            normalize={v => v.toUpperCase()}
                                            validate={required}
                                        />
                                    </div>
                                </div>

                                <QuestionLabel className={'f-16 mt-4'}>Ville / Code postal<sup className={'text-danger'}>*</sup></QuestionLabel>
                                <div className={'row'}>
                                    <div className={'col-12 col-md-6 mt-3 mt-md-4'} data-name="DemandeContrat[villeIBAN]">
                                        <PostalCodeCity
                                            id={'postalcode-iban'}
                                            nameVille={'DemandeContrat[villeIBAN]'}
                                            nameCodePostal={'DemandeContrat[cpIBAN]'}
                                            cityPlaceholder={'Ville'}
                                            postalPlaceholder={'Code postal'}
                                            validate={required}
                                            formName={'devis'}
                                            defaultValue={postalCodeCity}
                                            changeValue={changeValue}
                                        />
                                    </div>
                                </div>

                                <QuestionLabel className={'f-16 mt-4'}>IBAN<sup className={'text-danger'}>*</sup></QuestionLabel>
                                <div className={'row'}>
                                    <div className={'col-12 col-md-6 mt-3 mt-md-4'} data-name="DemandeContrat[IBAN]">
                                        <Field
                                            component={Text}
                                            name={'DemandeContrat[IBAN]'}
                                            validate={required}
                                            normalize={value => value && value.toUpperCase()}
                                        />

                                        {
                                            this.state.errorIban && <div className={'alert-danger alert mb-0'} dangerouslySetInnerHTML={{__html: this.state.errorIban}} />
                                        }

                                        {
                                            ibanIsCheck && !this.state.errorIban && <div className={'alert-success alert mb-0'}>
                                                l'IBAN est valide
                                            </div>
                                        }
                                    </div>
                                </div>

                                <span
                                    color='secondary'
                                    className={`${this.state.loadingIban && 'disabled'} btn btn-primary medium-rounded my-3`}
                                    style={{minHeight: 50, minWidth: 230}}
                                    onClick={this.state.loadingIban ? null : () => fetchCheckIban(this.props.ibanValue)}
                                >
                                    {
                                        this.state.loadingIban ? <div className="spinner-border" role="status" style={{width: 19, height: 19}}>
                                                <span className="sr-only" />
                                            </div>
                                            : "Vérifier l'IBAN"
                                    }

                                </span>

                                {
                                    ibanIsCheck && <div>
                                        <QuestionLabel className={'f-16 mt-4'}>Code BIC&nbsp;<sup className={'text-danger'}>*</sup></QuestionLabel>
                                        <div className={'row'}>
                                            <div className={'col-12 col-md-6 mt-3 mt-md-4'}>
                                                <Field
                                                    component={Text}
                                                    name={'DemandeContrat[BIC]'}
                                                    normalize={v => v.toUpperCase()}
                                                    validate={required}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }
                            </>
                        }
                        {
                            (_.toUpper(nomPrenom) !== _.toUpper(nomTitulaire)) && <TiersPayeurContainer {...this.props}/>
                        }
                        {
                            isAvenant && source === 'conseiller' ? <>
                                <QuestionLabel className={'f-16 mt-4'}>Date et heure d'effet&nbsp;<sup className={'text-danger'}>*</sup></QuestionLabel>
                                <div className={'row'}>
                                    <div className={'col-12 col-md-6 mt-3 mt-md-4'}>
                                        <Field
                                            component={DateField}
                                            name={'DemandeContrat[DateHeureEffet]'}
                                            label={'JJ/MM/AAAA HH:MM'}
                                            validate={[required, maxDate30Days]}
                                            minDate={null}
                                            maxDate={new Date(moment().add(30, 'days'))}
                                        />
                                    </div>
                                </div>
                            </> : null
                        }

                        { (source !== 'site' && !isAvenant) && <>
                            <Title size={16} icon={'Shape'} className={'mt-5'} dangerouslySetInnerHTML={{__html: 'Validation du contrat' }}/>

                            <QuestionLabel className={'f-16 mt-4'}>Souhaitez-vous utiliser l'envoi du lien de paiement pour valider le contrat ?</QuestionLabel>
                            <div className={'content-radio'}>
                                <Field
                                    name={'lienPaiementEmail'}
                                    component={Radio}
                                    className={'small'}
                                    type={'radio'}
                                    label={'Oui'}
                                    value={'true'}
                                    onChange={(e) => changeLienPaiement(e.target.value)}
                                />
                                <span style={{margin: '0 5px'}}/>
                                <Field
                                    name={'lienPaiementEmail'}
                                    component={Radio}
                                    className={'small'}
                                    type={'radio'}
                                    label={'Non'}
                                    value={'false'}
                                    onChange={(e) => changeLienPaiement(e.target.value)}
                                />
                            </div>
                        </> }

                        {loading
                            ? <><SmallSpinner/></>
                            : <div className={'mt-5 text-center'}>
                                <button
                                    className={`btn btn-block btn-primary btn-arrow medium-rounded text-uppercase mb-2`}
                                    disabled={invalid || submitting ||loading}
                                    onClick={handleSubmit(values =>
                                        this.props.onSubmit({
                                            ...values,
                                            destination: 'souscription'
                                        }))}
                                >
                                    {contenuText.text_souscription}
                                </button>
                            </div>
                        }
                    </div>

                    <div className={'col-lg-4 d-lg-inline-block'}>
                        <SidebarContainer analyticsSetEvent={analyticsSetEvent}/>
                    </div>

                </form>

                <form className={'invisible'} action={url_pay.uri} method={'POST'} >
                    <input type="text" name='token' defaultValue={url_pay.token} />
                    <input type="text" name='url_return' defaultValue={url_pay.url_return}/>
                    <button type={'submit'} ref={el => this.buttonElement = el} />
                </form>
            </div>
        );
    }
};

export default Recapitulatif;
