import {connect} from 'react-redux';
import App from './App';
import {bindActionCreators, compose} from "redux";
import {fetchLinks, LINKS} from "../../redux/actions/app/links/links.actions";
import {APP_URL_PASS} from '@amo/core/constants';
import {getLoading, getLoadingEntity} from "../../redux/selectors/ui/ui.selectors";
import {LargeSpinner, loader} from "@amo/core/components/loaders";

const mapStateToProps = state => {
    return {
        loaded: getLoading(state) !== undefined && getLoadingEntity(state, LINKS) === false
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    load: () => fetchLinks({query: APP_URL_PASS})
}, dispatch);

const AppContainer = compose(
    connect(mapStateToProps, mapDispatchToProps),
    loader({LoadingIndicator: LargeSpinner})
)(App);

export default AppContainer
