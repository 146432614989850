import React from 'react';
import { Title } from '@amo/core/components/containers'

const Proposition = (props) => {
    const {source, previousPage, emailSouscripteur, url_pdf_devis, url_pdf_ficheconseil, url_pdf_disposition, contenuText, url_pdf_information, handleSubmit, setStep } = props

    return (
        <div className={'steps-formulaire'}>
            <form onSubmit={handleSubmit} className={'d-flex'}>
                <div className={'container'}>
                    <div className={'row'}>
                        <div className={'col text-center'}>
                            <span className={`icon-trottinette text-primary`} style={{fontSize: 64}} />
                            <Title className={'mt-5 f-26 mb-1'} dangerouslySetInnerHTML={{__html: contenuText.prop_assurance }}/>
                            <p dangerouslySetInnerHTML={{__html: contenuText.prop_email}}/>
                            {contenuText.prop_email !== '' && <strong className={"d-block"}>{emailSouscripteur}</strong>}
                            <button className={'btn btn-block btn-primary medium-rounded text-uppercase mb-2 col-6 mt-5 mx-auto'} onClick={previousPage}>
                                Je finalise la souscription immédiatement
                            </button>
                        </div>
                    </div>

                    <div className={'row'}>
                        <div className={'col mt-5'}>
                            <a href={url_pdf_devis} target={'_blank'}>
                                <span className={'btn btn-outline-primary px-5 py-3 rounded-pill d-none d-lg-inline-block w-100'}>
                                    Proposition&nbsp;d'assurance
                                </span>
                            </a>
                        </div>
                        {source === 'site' &&
                        <div className={'col mt-5'}>
                            <a href={url_pdf_ficheconseil} target={'_blank'}>
                                <span className={'btn btn-outline-primary px-5 py-3 rounded-pill d-none d-lg-inline-block w-100'}>
                                    Fiche&nbsp;conseil
                                </span>
                            </a>
                        </div>}
                        <div className={'col mt-5'}>
                            <a href={url_pdf_disposition} target={'_blank'}>
                                <span className={'btn btn-outline-primary px-5 py-3 rounded-pill d-none d-lg-inline-block w-100'}>
                                    Dispositions&nbsp;Générales
                                </span>
                            </a>
                        </div>
                        <div className={'col mt-5'}>
                            <a href={url_pdf_information} target={'_blank'}>
                                <span className={'btn btn-outline-primary px-5 py-3 rounded-pill d-none d-lg-inline-block w-100'}>
                                    Document&nbsp;d'information
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}
export default Proposition
