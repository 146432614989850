import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader, setStep} from "../../../actions/app/ui/ui.actions";
import {CHECK_STATUS, FETCH_CHECK_STATUS} from "../../../actions/app/checkStatus/checkStatus.actions";
import {setInit} from "../../../actions/app/init/init.actions";
import _ from "lodash";
import {setContract} from "../../../actions/app/contract/contract.actions";

export const checkStatusMiddleware = () => next => action => {
    next(action)

    const { payload } = action

    switch (action.type){

        case FETCH_CHECK_STATUS:

            next([
                apiRequest({body: payload.body, method: 'POST', url: payload.data, entity: CHECK_STATUS}),
                setLoader({state: true, entity: CHECK_STATUS})
            ])

            break

        case `${CHECK_STATUS} ${API_SUCCESS}`:
            const signature = _.head(payload.data.signatures)
            const isSign = _.some(_.map(['sign_return'], w => window.location.href.includes(w)), Boolean)

            const nextActions = [
                setInit({init: payload.data}),
                setError({state: false, entity: CHECK_STATUS}),
                setLoader({state: false, entity: CHECK_STATUS})
            ]

            if ( payload.data.state !== "contrat" || (payload.data.state === "contrat" && payload.data.data.source !== 'courtier' && payload.data.data.source !== 'conseiller')) {
                nextActions.push(setContract({data: payload.data.data.Contrat}))
                nextActions.push(setStep({step: isSign ? 6 : (signature && !signature.signed && !signature.expired && !signature.refused) ? 5 : 6}))
            } else if( payload.data.state === "contrat") {
                nextActions.push(setContract({data: payload.data.data.Contrat}))
                nextActions.push(setStep({step: 6}))
            }

            next(nextActions)
            break

        case `${CHECK_STATUS} ${API_ERROR}`:
            next([
                setError({state: true, entity: CHECK_STATUS}),
                setLoader({state: false, entity: CHECK_STATUS})
            ])
            break

        default:
            return null
    }
}
