import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader, setNextPage} from '../../../actions/app/ui/ui.actions'
import {POST_FILL, FILL} from "../../../actions/app/fill/fill.actions";
import _ from 'lodash'
import {formatDataForApi} from "./formatDataForApi";
import {analyticsSetEvent} from "../../../actions/core/analytics/analytics.actions.js";

export const fillMiddleware = () => next => action => {
    next(action)

    const { payload } = action

    switch (action.type){

        case POST_FILL:
            let body = formatDataForApi(payload.body)
            body = payload.meta.otherData.step < 2 && payload.meta.otherData.acteGestion === 'AN' ? _.omit(body, 'DemandeTarif[ListePersonnes]') : body
            
            next([
                apiRequest({body, method: 'POST', url: payload.data, entity: FILL, otherData: payload.meta.otherData}),
                setLoader({state: true, entity: FILL})
            ])
            break

        case `${FILL} ${API_SUCCESS}`:
            const {step, maxStep} = action.payload.meta.otherData
            window.scroll(0, 0)
            next([
                setNextPage({step: step + 1, maxStep}),
                setError({state: false, entity: FILL}),
                setLoader({state: false, entity: FILL})
            ])
            break

        case `${FILL} ${API_ERROR}`:
            next([
                analyticsSetEvent({event: 'errorMessage', ap1: payload?.meta?.otherData?.ap1, datas: {
                    'errorMessage': {'label': action.payload?.data?.response?.data?.error}
                }}),
                setError({state: true, entity: FILL}),
                setLoader({state: false, entity: FILL})
            ])
            break

        default:
            return null
    }
}
