import React, {Fragment, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import FooterContainer from "./Footer/FooterContainer";
import './formulaire.scss'
import TopBarContainer from "./TopBar/TopBarContainer";
import DemandeRappelContainer from "./DemandeRappel/DemandeRappelContainer";
import BesoinContainer from "./Besoin/BesoinContainer";
import TarifLoadContainer from "./Tarif/TarifLoadContainer.js";
import InformationsContainer from "./Informations/InformationsContainer.js";
import RecapitulatifContainer from "./Recapitulatif/RecapitulatifContainer.js";
import PropositionContainer from "./Proposition/PropositionContainer.js";
import PaiementEtapesContainer from "./PaiementEtapes/PaiementEtapesContainer.js";
import SignatureContainer from "./Signature/SignatureContainer.js";
import RetourSignatureContainer from "./RetourSignature/RetourSignatureContainer.js";
import AnnulationPaiementContainer from "./AnnulationPaiement/AnnulationPaiementContainer.js";
import ValidationContainer from "./Validation/ValidationContainer.js";
import ImgAvatar from '../../../../assets/images/avatar.png'
import ImgHeader from '../../../../assets/images/header.png'
import Header from "@amo/core/components/containers/Header/Header.jsx";
import {formatDataForAnalytics} from "@amo/core/utils/formatDataForAnalytics.js";
import {getTitleByStep} from "../../../../utils/function.js";

const Formulaire = (props) => {
    const {step, nextPage, previousPage, source, paiementSendByMail, analyticsSetEvent, prixTotalAnnuel, numeroContrat, data, apporteur} = props
    const [formRappelAffiche, setFormRappelAffiche] = useState(false);
    const initialValuesLoaded = data !== undefined;

    useEffect(() => {
        if (data !== undefined ) {
            analyticsSetEvent({
                event: 'funnelStepLoad',
                ap1: apporteur,
                datas: {
                    'funnelStepLoad': {
                        'stepName': getTitleByStep(step),
                        'parentStepName': '',
                        'currentInfo': formatDataForAnalytics('trottinette', {...data, yealyQuote: prixTotalAnnuel, numeroContrat: numeroContrat}),
                    }
                }
            });
        }
}, [initialValuesLoaded, step]);

    return (
        <div className={'position-relative'}>
            <TopBarContainer analyticsSetEvent={analyticsSetEvent} formRappelAffiche={formRappelAffiche} setFormRappelAffiche={setFormRappelAffiche} previousPage={previousPage} />
            {step === 0 &&
                <Fragment>
                    <Header product={"e-trottineur"} imgBG={ImgHeader} avatar={ImgAvatar} source={source} step={step}/>
                    <BesoinContainer onSubmit={nextPage} analyticsSetEvent={analyticsSetEvent} apporteur={apporteur}/>
                </Fragment>
            }

                {step === 1 &&
            <Fragment>
                <Header product={"e-trottineur"} imgBG={ImgHeader} avatar={ImgAvatar} source={source} step={step}/>
                {
                    (source === 'conseiller' || source === 'courtier') && paiementSendByMail && <div className="row m-0 p-0">
                        <div className="col col-md-6 offset-md-3">
                            <div className="alert alert-danger f-14 mt-5 text-center" role="alert">
                                <b>Attention: Votre client a déjà reçu un email pour effectuer le paiement de son contrat.</b><br/><br/>
                                Le contrat sera validé dès que le paiement aura été validé.<br/><br/>
                                Vous pouvez continuer pour reprendre le devis.
                            </div>
                        </div>
                    </div>
                }
                <div className="steps-formulaire">
                    <TarifLoadContainer onSubmit={nextPage} previousPage={previousPage}/>
                </div>
            </Fragment>
            }
            {step === 2 &&
                <Fragment>
                    <Header product={"e-trottineur"} imgBG={ImgHeader} avatar={ImgAvatar} source={source} step={step}/>
                    <InformationsContainer previousPage={previousPage} nextPage={nextPage} />
                </Fragment>
            }
            {step === 3 &&
            <Fragment>
                <Header product={"e-trottineur"} imgBG={ImgHeader} avatar={ImgAvatar} source={source} step={step}/>
                <RecapitulatifContainer previousPage={previousPage} />
            </Fragment>
            }
            {step === 4 &&
                <Fragment>
                    <Header product={"e-trottineur"} imgBG={ImgHeader} avatar={ImgAvatar} source={source} step={step}/>
                    <PropositionContainer previousPage={previousPage} />
                </Fragment>
            }
            {step === 4.5 && <PaiementEtapesContainer/> }
            {step === 5 && <SignatureContainer />}
            {step === 6 && <RetourSignatureContainer source={source}/>}
            {step === 7 &&
                <Fragment>
                    <Header product={"e-trottineur"} imgBG={ImgHeader} avatar={ImgAvatar} source={source} step={step}/>
                    <AnnulationPaiementContainer />
                </Fragment>
            }
            {step === 8 && <ValidationContainer />}

            <DemandeRappelContainer formRappelAffiche={formRappelAffiche} setFormRappelAffiche={setFormRappelAffiche} />
            <FooterContainer/>
        </div>
    )
}

Formulaire.defaultProps = {
    step: 0,
    nextPage: () => {console.log('next page')},
    previousPage: () => {console.log('previous page')}
}

Formulaire.propTypes = {
    step: PropTypes.number,
    nextPage: PropTypes.func,
    previousPage: PropTypes.func
}

export default Formulaire
