import {connect} from 'react-redux';
import Proposition from './Proposition';
import {getSource, getToken} from "../../../../../redux/selectors/init/init.selectors";
import {formValueSelector} from "redux-form";
import {getUrlPDFDevis, getUrlPDFFicheConseil} from "../../../../../redux/selectors/devis/devis.selectors";
import {
    getUrlPDFDispositionsGenerales,
    getUrlPDFDocumentInformation
} from "../../../../../redux/selectors/tarif/tarif.selectors";
import {API_URL_WS_FORMULAIRE, APP_URL_APPLICATION} from '@amo/core/constants';
import contenu from "../../../../../constants/contenu_text";
import {setStep} from "../../../../../redux/actions/app/ui/ui.actions";

const mapStateToProps = state => {
    const selector = formValueSelector('devis')

    return {
        token: getToken(state),
        emailSouscripteur: selector(state, 'DemandeTarif[ListePersonnes][0][Email]'),
        url_pdf_devis: getUrlPDFDevis(state),
        url_pdf_information: getUrlPDFDocumentInformation(state),
        url_pdf_ficheconseil: getUrlPDFFicheConseil(state),
        url_pdf_disposition: getUrlPDFDispositionsGenerales(state),
        url_pay: {
            uri: `${API_URL_WS_FORMULAIRE}/pay/${getToken(state)}`,
            token: getToken(state),
            url_return: APP_URL_APPLICATION
        },
        contenuText : contenu[getSource(state)],
        source: getSource(state)
    }
}

const mapDispatchToProps = {
    setStep
}

const PropositionContainer = connect(mapStateToProps, mapDispatchToProps)(Proposition)

export default PropositionContainer
