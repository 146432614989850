import {connect} from 'react-redux'
import RetourSignature from './RetourSignature'
import {
   getActeGestionDemandeTarifInit,
   getDocumentsUrlInit,
   getEmailApporteur,
   getSignature,
   getSource,
   getToken
} from "../../../../../redux/selectors/init/init.selectors";
import {
   getDocumentCI,
   getListeDocument,
   getUrlPDFAttestationNvei, getUrlPDFContrat, getUrlPDFMandatSepa
} from "../../../../../redux/selectors/contract/contract.selectors";
import {CONTRACT} from "../../../../../redux/actions/app/contract/contract.actions";
import {API_URL_WS_FORMULAIRE} from '@amo/core/constants';

const mapStateToProps = state => {
   return {
      documentsUrl: getDocumentsUrlInit(state),
      signature: getSignature(state),
      listDocument: getListeDocument(state),
      entity: CONTRACT,
      uploadLink: `${API_URL_WS_FORMULAIRE}/upload/${getToken(state)}`,
      identityCard: getDocumentCI(state),
      emailApporteur: getEmailApporteur(state),
      source: getSource(state),
      isAvenant: getActeGestionDemandeTarifInit(state) === 'AV',
      pdfAttestation: getUrlPDFAttestationNvei(state),
      pdfContrat: getUrlPDFContrat(state),
      pdfSepa: getUrlPDFMandatSepa(state)
   }
}

const RetourSignatureContainer = connect(mapStateToProps)(RetourSignature)

export default RetourSignatureContainer
