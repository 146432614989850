import React from 'react';
import './sidebar.scss'
import _ from 'lodash'
import moment from "moment";

const Sidebar = (props) => {
    const {values, isDirect, setStep, countries, analyticsSetEvent, apporteur} = props
    const country = values.DemandeTarif['ListePersonnes'][0]['BirthCountry'] ? _.find(countries, ['id', values.DemandeTarif['ListePersonnes'][0]['BirthCountry']]) : null;

    return (
        <div id={"sidebar"} className={'bg-grey mb-3 p-4'}>

            <p className={'subtitle mb-1'}><b>{isDirect ? 'Votre besoin' : 'Son besoin' }</b></p>
            <p className={'m-0'}>Trottinette limitée à 25km/h&nbsp;: <b>{_.upperFirst(values.typeVehicule)}</b></p>
            <p className={'m-0'}>Je déclare utiliser cette trottinette électrique pour des déplacements privés et/ou professionnels mais en aucun cas pour des tournées régulières, pour du transport à titre onéreux de marchandises ou de voyageurs.&nbsp;: <b>Oui</b></p>
            <p className={'m-0'}>Souhait d'être indemnisé en cas d'accident responsable avec de graves blessures voire décès&nbsp;: <b>{values.DemandeTarif['DevoirConseil']['DevoirIC'] === "1" ? "Oui" : "Non"}</b></p>
            <p className={'m-0'}>Souhait d'assurer le conjoint ou les enfants lorsqu'ils utilisent une trottinette électrique&nbsp;: <b>{values.DemandeTarif['DevoirConseil']['DevoirUsage'] === "2" ? "Oui" : "Non"}</b></p>

            <p className={'subtitle mt-4 mb-1'}><b>{isDirect ? 'Votre formule et options' : 'Sa formule et options' }</b></p>
            <p className="m-0">Formule&nbsp;: <b>{values.DemandeTarif['Police']['FormuleChoisie'] === "F1" ? "Formule solo" : "Formule familliale"}</b></p>
            <p className="m-0">Option protection en cas d'invalidité ou décès&nbsp;: <b>{values.DemandeTarif['ListeOptions'][0]['Souscrite'] ? "Oui" : "Non"}</b></p>

            <p className={'subtitle mt-4 mb-1'}><b>{isDirect ? 'Vos informations' : 'Ses informations' }</b></p>
            <p className="m-0">Civilité&nbsp;: <b>{values.DemandeTarif['ListePersonnes'][0]['Civilite'] === "1" ? "Madame" : "Monsieur"}</b></p>
            <p className="m-0">Nom d'usage&nbsp;: <b>{values.DemandeTarif['ListePersonnes'][0]['Nom']}</b></p>
            <p className="m-0">Nom de naissance&nbsp;: <b>{values.DemandeTarif['ListePersonnes'][0]['BirthName']}</b></p>
            <p className="m-0">Prénom&nbsp;: <b>{values.DemandeTarif['ListePersonnes'][0]['Prenom']}</b></p>
            <p className="m-0">Date de naissance&nbsp;: <b>{moment.isMoment(values.DemandeTarif['ListePersonnes'][0]['DateNaissance'])? values.DemandeTarif['ListePersonnes'][0]['DateNaissance'].format('L') : values.DemandeTarif['ListePersonnes'][0]['DateNaissance']}</b></p>
            <p className="m-0">Pays de naissance&nbsp;: <b>{country ? country.name : null}</b></p>
            <p className="m-0">Ville de naissance&nbsp;: <b>{values.DemandeTarif['ListePersonnes'][0]['BirthCity']}</b></p>
            <p className="m-0">Adresse&nbsp;: <b>{values.DemandeTarif['ListePersonnes'][0]['Adresse1']}</b></p>
            { values.DemandeTarif['ListePersonnes'][0]['Adresse2'] && <p className={'m-0'}>Complément d'adresse&nbsp;: <b>{values.DemandeTarif['ListePersonnes'][0]['Adresse2']}</b></p> }
            <p className="m-0">Ville, Code postal&nbsp;: <b>{values.DemandeTarif['ListePersonnes'][0]['Ville']} {values.DemandeTarif['ListePersonnes'][0]['CP']}</b></p>
            <p className="m-0">Numéro de téléphone&nbsp;: <b>{values.DemandeTarif['ListePersonnes'][0]['TelPortable']}</b></p>
            <p className="m-0">Email&nbsp;: <b>{values.DemandeTarif['ListePersonnes'][0]['Email']}</b></p>

            {
                values.DemandeTarif['Police']['FormuleChoisie'] === "F2" && <p className="m-0">Je reconnais avoir été informé(e) que dans le cadre de la formule Famille, seuls sont assurés les enfants qui possèdent l’Attestation Scolaire de Sécurité Routière de Niveau 2&nbsp;: <b>{values.DemandeTarif['ListePersonnes'][0]['DeclaASSR2'] ? "Oui, je reconnais" : "Je n'ai pas d'enfant"}</b></p>
            }

        </div>
    );
};

export default Sidebar;

