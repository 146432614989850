import { createStore, applyMiddleware, compose } from 'redux'
import logger from 'redux-logger'
import AppReducer from './AppReducers'
import {coreMiddleware} from '../middlewares/core'
import {appMiddleware} from "../middlewares/app"
import {APP_ENV} from '@amo/core/constants'

const middlewares = [...appMiddleware, ...coreMiddleware]

if (APP_ENV !== 'production') {
    middlewares.push(logger)
}

//const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export const store = createStore(
    AppReducer,
    compose(
        applyMiddleware(...middlewares)
    )
)
