import {connect} from 'react-redux'
import Sidebar from "./Sidebar";
import {getFormValues} from "redux-form";
import {getCodeBelairApporteur1Init, getIsDirect} from "../../../../../../redux/selectors/init/init.selectors";
import {setStep} from "../../../../../../redux/actions/app/ui/ui.actions";
import {getListCountries} from "../../../../../../redux/selectors/ui/ui.selectors";
import {analyticsSetEvent} from "../../../../../../redux/actions/core/analytics/analytics.actions.js";

const mapStateToProps = state => {

    return {
        values: getFormValues('devis')(state),
        isDirect: getIsDirect(state),
        countries: getListCountries(state),
        apporteur: getCodeBelairApporteur1Init(state)
    }
}

const mapDispatchToProps = {
    setStep,
    analyticsSetEvent
}

const SidebarContainer = connect(mapStateToProps, mapDispatchToProps)(Sidebar)

export default SidebarContainer
