import React, {useEffect} from 'react';
import {LargeSpinner} from "@amo/core/components/loaders";

const Validation = (props) => {
    const { onSubmitCheckStatus } = props

    useEffect(() => {
        setTimeout(onSubmitCheckStatus(), 2500)
    })

    return ( <LargeSpinner/> );
};

export default Validation;
