import {createSelector} from 'reselect'
import _ from "lodash";
import {getInfoCodePromo, getListeFormulesTarif} from "../tarif/tarif.selectors";

/* UI */
const uiSelector = state => state.ui
const formDevisSelector = state => state.form.devis.values || {}

/* Maintenance */
export const getMaintenance = createSelector(
    uiSelector,
    ui => ui.maintenance
)

/* Loading */
const loadingEntitySelector = (state, entity) => state.ui.loading[entity]

export const getLoading = createSelector(
    uiSelector,
    ui => ui.loading
)

export const getLoadingEntity = createSelector(
    loadingEntitySelector,
    loading => loading
)

/* ErrorStyled */
const errorEntitySelector = (state, entity) => state.ui.error[entity]

export const getErrorEntity = createSelector(
    errorEntitySelector,
    error => error
)

/* Step */
export const getStep = createSelector(
    uiSelector,
    ui => ui.step
)

export const getMaxStep = createSelector(
    uiSelector,
    ui => ui.maxStep
)

/* Formule */

export const getFormuleChoice = createSelector(
    uiSelector,
    ui => ui.formule ? ui.formule : undefined
)

export const getCodeFormuleChoice = createSelector(
    getFormuleChoice,
    formule => {
        if (formule){
            return formule.CodeFormule
        }
        return false
    }
)

export const getMontantBaseFormuleChoice = createSelector(
    getFormuleChoice,
    formule => {
        if (formule){
            const listeGaranties = _.filter(formule.ListeGaranties, {'Disponible': 'Inclusion'})
            return _.round(_.sum(_.map(listeGaranties, 'PrimeClient')))
        }
        return false
    }
)

export const getLibelleFormuleChoice = createSelector(
    getFormuleChoice,
    formule => formule.LibelleFormule
)

export const getPrimeClientPCC1 = createSelector(
    getFormuleChoice,
    formule => {
        if (formule) {
            const pcc1 = _.find(formule.ListeGaranties, ['CodeGarantie', 'PCC1'])
            return pcc1 ? pcc1.PrimeClient : null
        }
        return false
    }
)

export const getPCC1IsSelected = createSelector(
    getFormuleChoice,
    formule => {
        if (formule) {
            const pcc1 = _.find(formule.ListeGaranties, ['CodeGarantie', 'PCC1'])
            return pcc1.Souscrite
        }
        return false
    }
)

export const getGoToPay = createSelector(
    uiSelector,
    ui => ui.goToPay
)

export const getComptantAnnuel = createSelector(
    getFormuleChoice,
    formule => {
        if (formule) {
            return formule.ComptantAnnuel
        }
        return false
    }
)

export const getMontantCommApporteurReglette = createSelector(
    getFormuleChoice,
    formule => {
        if (formule) {
            return formule.MontantCommApporteurReglette
        }
        return false
    }
)


/* Upload */
const selectedUploadSelector = (state) => state.ui.upload

export const getUploads = createSelector(
    selectedUploadSelector,
    uploads => uploads
)

/*  Gestion du prix total et prix total promo de la page tarif (gestion des garanties sélectionnées) */
// En cas d'ajout d'options ou d'ajout du mensuel voir selector velo/direct
export const getPrix = createSelector(
    formDevisSelector,
    getListeFormulesTarif,
    getInfoCodePromo,
    (formDevis, listeFormules, infosPromo) => {
        const formuleChoisie = _.find(listeFormules, ['CodeFormule', formDevis.DemandeTarif.Police.FormuleChoisie]) || {};
        let price = null;
        let price_promo = null;

        if(formuleChoisie.PrimeAnnuelleFracAnnuelBase){
            price = formuleChoisie.PrimeAnnuelleFracAnnuelBase;
            price_promo = formuleChoisie.PrimeAnnuelleFracAnnuelBasePromo;
        }

        if(price !== null){
            // Spécifique à la trottinette
            if(formDevis.DemandeTarif.ListeOptions){
                const optionsSelected = _.filter(formDevis.DemandeTarif.ListeOptions, {'Souscrite': true})
                _.forEach(optionsSelected, function(optionSelected) {
                    if(optionSelected.CodeOption === 'PCC1'){
                        const pcc1 = _.find(formuleChoisie.ListeGaranties, ['CodeGarantie', 'PCC1'])
                        return pcc1 ? price += pcc1.PrimeClient : null
                    }
                });
            }
        }
        price_promo = infosPromo && infosPromo.Eligible ? price_promo : price;

        return {
            'price' : price,
            'price_promo' : price_promo,
            'annual_price' : price,
            'annual_price_promo' : price_promo,
        }
    }
)
export const getPrixTotal = createSelector(
    getPrix,
    prices => prices.price
)

export const getPrixTotalPromo = createSelector(
    getPrix,
    prices => prices.price_promo
)

export const getUtmSource = createSelector(
    uiSelector,
    ui => ui.locationSearch.utm_source
)

export const getPrixTotalAnnuel = createSelector(
    getPrix,
    prices => prices.annual_price
)

export const getPrixTotalAnnuelPromo = createSelector(
    getPrix,
    prices => prices.annual_price_promo
)

export const getListCountries = createSelector(
    uiSelector,
    ui => ui.countries
)

