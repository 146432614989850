import _ from 'lodash'

/**
 * Ajout les champs obligatoire pour avoir un devis
 * @param payload
 * @returns {object}
 */
export const setFieldForUselessAPI = (payload) => {
    _.set(payload, 'DemandeTarif[ListePersonnes][0][RoleConducteur]', 'P')
    _.set(payload, 'DemandeTarif[ListePersonnes][0][TypePersonne]', 'P')
    _.set(payload, 'DemandeTarif[ListePersonnes][0][Souscripteur]', true)
    _.set(payload, 'DemandeTarif[ListePersonnes][0][TelPortable]', _.replace(_.get(payload, 'DemandeTarif[ListePersonnes][0][TelPortable]'), /\s/g, ''))
    _.set(payload, 'DemandeTarif[Police][FractionnementChoisi]', 'A')
    _.set(payload, 'DemandeTarif[Vehicule][CodePostalGarage]', _.get(payload, 'DemandeTarif[ListePersonnes][0][CP'))
    _.set(payload, 'DemandeTarif[Vehicule][VilleGarage]', _.get(payload, 'DemandeTarif[ListePersonnes][0][Ville'))
    _.set(payload, 'DemandeContrat[IBAN]', _.get(payload, 'DemandeContrat[IBAN]') ? _.get(payload, 'DemandeContrat[IBAN]').replaceAll('-', '') : null)

    return payload
}