import {connect} from 'react-redux'
import {compose} from 'redux'
import {change, formValueSelector, reduxForm} from "redux-form";
import validate from "../Tarif/Tarif/validate";
import {postSaveDevis, SAVE_DEVIS} from "../../../../../redux/actions/app/save_devis/save_devis.actions";
import {API_URL_WS_FORMULAIRE, APP_URL_APPLICATION} from '@amo/core/constants';
import {getActeGestionDemandeTarifInit, getSource, getToken} from "../../../../../redux/selectors/init/init.selectors";
import Recapitulatif from "./Recapitulatif";
import {getGoToPay, getLoadingEntity, getPrixTotalAnnuel} from "../../../../../redux/selectors/ui/ui.selectors";
import contenu from "../../../../../constants/contenu_text";
import {getListeFormule} from "../../../../../redux/selectors/tarif/tarif.selectors";

const mapStateToProps = state => {
    const selector = formValueSelector('devis')
    const ville = selector(state, 'DemandeContrat[villeIBAN]')
    const cp = selector(state, 'DemandeContrat[cpIBAN]')

    return {
        postalCodeCity: ville ? `${ville} ${cp}` : '',
        loading: getLoadingEntity(state, SAVE_DEVIS),
        formule: selector(state, 'DemandeTarif[Police][FormuleChoisie]'),
        listeFormules: getListeFormule(state),
        source: getSource(state),
        goToPay: getGoToPay(state),
        isAvenant: getActeGestionDemandeTarifInit(state) === 'AV',
        contenuText : contenu[getSource(state)],
        url_pay: {
            uri: `${API_URL_WS_FORMULAIRE}/pay/${getToken(state)}`,
            token: getToken(state),
            url_return: APP_URL_APPLICATION
        },
        token: getToken(state),
        prixTotalAnnuel: getPrixTotalAnnuel(state),
        ibanIsCheck: Boolean(selector(state, 'DemandeContrat[BIC]')),
        ibanValue: selector(state, 'DemandeContrat[IBAN]'),
        nomTitulaire: selector(state, 'DemandeContrat[NomTitulaire]'),
        nomPrenom: selector(state, 'DemandeTarif[ListePersonnes][0][Nom]')+ ' '+selector(state, 'DemandeTarif[ListePersonnes][0][Prenom]'),
    }
}

const mapDispatchToProps = {
    onSubmit: (form, token) => postSaveDevis({form, token}),
    changeValue: (field, value) => change('devis', field, value)
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { onSubmit } = dispatchProps
    const { token } = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: (form) => {
            onSubmit(form, token)
        }
    }
}

const RecapitulatifContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'devis',
        destroyOnUnmount: false,
        validate
    }),
)(Recapitulatif)

export default RecapitulatifContainer
