import styled from "styled-components";
import {Theme} from "../../../../constants/constants.js";

export const FormErrorStyled = styled.div`
  width: 100%;
  margin: 20px 0 0 0;
  padding: 15px 24px;
  color: ${Theme.color.error.color1};
  border: 1px solid ${Theme.color.error.color1};
  background: ${Theme.color.error.color4};
  border-radius: 10px;
  span {
    position: relative;
    top: 2px;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 10px 0 0;
    font-size: 20px;
    float: left;
  }
`;

export const FormErrorText = styled.p`
  margin: 0;
  font-family: ${Theme.font.secondary};
  font-size: 16px;
  font-weight: 600;
  overflow: hidden;
`;