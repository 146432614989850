import {Field} from "redux-form";
import BulleAide from "../BulleAide/BulleAide";
import {Radio, Checkbox} from "@amo/core/components/forms";
import TrottinetteTropPuissante from "@amo/core/assets/images/produits/trottinette-trop-puissante.svg"
import TrottinetteBonnePuissance from "@amo/core/assets/images/produits/trottinette-bonne-puissance.svg"
import {QuestionLabel, ChampsObligatoires, Title} from "@amo/core/components/containers";
import { required } from '@amo/core/utils/validateField.js'

const Besoin = (props) => {
    const {handleSubmit, invalid, submitting, loading, typeVehicule, contenuText, isCourtier, canUseBesoin, unsetValue, recueilBesoinActif, isAvenant, isConseiller, analyticsSetEvent, apporteur} = props

    return (
        <div className={'container steps-formulaire'}>
            <form onSubmit={handleSubmit} className='row'>
                <div className={'col-lg-8'} id={'besoins'}>
                    {
                        isAvenant && <div className={'mb-3 mb-md-4'}>
                            <Title icon={'security'} dangerouslySetInnerHTML={{__html: 'Motif de l\'avenant ?'}}/>
                            <div className={'row'}>
                                <div className={'col-12 col-md-6 mt-3 mt-md-4'}>
                                    <Field
                                        component={Radio}
                                        name={'DemandeTarif[Police][MotifAvenant]'}
                                        value={'2'}
                                        type={"radio"}
                                        label={'Changement de garanties'}
                                        validate={required}
                                    />
                                </div>
                            </div>
                        </div>
                    }

                    <Title icon={'security'} dangerouslySetInnerHTML={{__html: 'Votre besoin'}}/>

                    <QuestionLabel className={'f-16'}>Est-ce que la trottinette est limitée à 25km/h&nbsp;?<sup className={'text-danger'}>*</sup></QuestionLabel>
                    <div className={'row'}>
                        <div className={'mt-3 mt-md-4 col-lg-6 col-md-6 col-12 text-center'}>
                            <Field
                                name={'typeVehicule'}
                                component={Radio}
                                type={'radio'}
                                label={'Oui, <br/>elle est limitée à 25km/h'}
                                icon={TrottinetteBonnePuissance}
                                value={'oui'}
                                onClick={() => analyticsSetEvent({
                                    event: 'limit25Km',
                                    ap1: apporteur,
                                    datas: {'limit25Km': {'button': 'Oui'}}
                                })}
                            />
                        </div>
                        <div className={'mt-3 mt-md-4 col-lg-6 col-md-6 col-12 text-center'}>
                            <Field
                                name={'typeVehicule'}
                                component={Radio}
                                type={'radio'}
                                label={'Non, <br/>je peux même aller encore plus vite'}
                                icon={TrottinetteTropPuissante}
                                value={'non'}
                                onClick={() => analyticsSetEvent({
                                    event: 'limit25Km',
                                    ap1: apporteur,
                                    datas: {'limit25Km': {'button': 'Non'}}
                                })}
                            />
                        </div>
                        {typeVehicule === 'non' && <div className={'col-12 mt-4'}>
                            <div className="alert alert-danger f-14" role="alert">Attention, nous n'assurons pas les
                                trottinettes qui roulent à plus de 25km/h.
                            </div>
                        </div>}
                    </div>

                    <div className={'row mt-5'}>
                        <div className={'col'}>
                            <Field
                                name="deplacements"
                                component={Checkbox}
                                type={"checkbox"}
                                className={'f-16 fw-600'}
                                validate={[required]}
                            >
                                <QuestionLabel loader className={'f-16 mt-0 ml-2 cursor-pointer'}>{contenuText.deplacements}<sup className={'text-danger'}>*</sup></QuestionLabel>
                            </Field>
                        </div>
                    </div>

                    {
                        (isCourtier) && <>
                            {
                                canUseBesoin ? <>
                                    <QuestionLabel className={'f-16 mt-4'}>Souhaitez-vous utiliser le Recueil du
                                        Besoin&nbsp;?<sup className={'text-danger'}>*</sup></QuestionLabel>
                                    <div className={'row'}>
                                        <div className={'col-12 col-md-6 mt-3 mt-md-4'}>
                                            <Field
                                                component={Radio}
                                                name={'DemandeTarif[UseRecueilBesoin'}
                                                value={'oui'}
                                                className={"small"}
                                                type={"radio"}
                                                label={'Oui'}
                                                validate={required}
                                                onChange={() => unsetValue('DemandeTarif[DevoirConseil]')}
                                            />
                                        </div>

                                        <div className={'col-12 col-md-6 mt-3 mt-md-4'}>
                                            <Field
                                                component={Radio}
                                                name={'DemandeTarif[UseRecueilBesoin]'}
                                                value={'non'}
                                                className={"small"}
                                                type={"radio"}
                                                label={'Non'}
                                                validate={required}
                                                onChange={() => unsetValue('DemandeTarif[DevoirConseil]')}
                                            />
                                        </div>
                                    </div>
                                </>
                                : <div className={'bg-grey p-4 rounded text-left mt-4'}>
                                    <p><b>Le Saviez-vous ?<br/>Vous avez la possibilité d'activer le Recueil du Besoin
                                        pour obtenir une Fiche Conseil.</b></p>
                                    <p>Si vous souhaitez bénéficier de ce service, vous avez la possibilité de remplir
                                        toutes les informations nécessaires à la personnalisation de la Fiche Conseil
                                        dans votre espace, rubrique "Mon profil".</p>
                                    <p>Si vous préférez utiliser votre document, vous pouvez poursuivre la
                                        souscription.</p>
                                </div>
                            }
                        </>
                    }

                    {(recueilBesoinActif || isConseiller) &&
                        <>
                            <QuestionLabel className={'f-16'}>{contenuText.besoin_dommage}<sup className={'text-danger'}>*</sup></QuestionLabel>
                            <div className={'row'}>
                                <div className={'col-12 col-md-6 mt-3 mt-md-4'}>
                                    <Field
                                        component={Radio}
                                        name={'DemandeTarif[DevoirConseil][DevoirIC]'}
                                        className={"small"}
                                        value={'1'}
                                        type={"radio"}
                                        label={'Oui'}
                                        validate={required}
                                    />
                                </div>

                                <div className={'col-12 col-md-6 mt-3 mt-md-4'}>
                                    <Field
                                        component={Radio}
                                        name={'DemandeTarif[DevoirConseil][DevoirIC]'}
                                        value={'0'}
                                        className={"small"}
                                        type={"radio"}
                                        label={'Non'}
                                        validate={required}
                                    />
                                </div>
                            </div>

                            <div className={'bg-grey p-4 rounded text-left mt-5'}>
                                <p dangerouslySetInnerHTML={{__html: contenuText.texte_info_pcc}}/>
                            </div>

                            <QuestionLabel className={'f-16'}>{contenuText.besoin_assurance_famille}<sup className={'text-danger'}>*</sup></QuestionLabel>
                            <div className={'row'}>
                                <div className={'col-12 col-md-6 mt-3 mt-md-4'}>
                                    <Field
                                        component={Radio}
                                        name={'DemandeTarif[DevoirConseil][DevoirUsage]'}
                                        value={'2'}
                                        type={"radio"}
                                        className={"small"}
                                        label={'Oui'}
                                        validate={required}
                                    />
                                </div>

                                <div className={'col-12 col-md-6 mt-3 mt-md-4'}>
                                    <Field
                                        component={Radio}
                                        name={'DemandeTarif[DevoirConseil][DevoirUsage]'}
                                        value={'1'}
                                        type={"radio"}
                                        className={"small"}
                                        label={'Non'}
                                        validate={required}
                                    />
                                </div>
                            </div>
                        </>
                    }

                    <ChampsObligatoires />

                    <div className={'row'}>
                        <div className={'col'}>
                            <div className={'mt-5 text-center'}>
                                <button className={`${(invalid || submitting || loading) && "disabled"} btn btn-primary medium-rounded cursor-pointer`}>
                                    Calculer mon tarif
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'col-lg-4 d-lg-inline-block'}>
                    <BulleAide etape={'0'} contenuText={contenuText}/>
                </div>
            </form>
        </div>
    );
};

export default Besoin;
