import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import PaiementEtapes from "./PaiementEtapes";
import {
    getIDDevisInDevisInit,
    getPaiementHistory,
    getStateContract
} from "../../../../../redux/selectors/init/init.selectors";
import {localStorageAMO} from "../../../../../redux/selectors/localStorage/localStorage.selectors";
import ErrorComponent from "../../../Maintenance/Error/ErrorComponent";
import {CHECK_STATUS, fetchStatus} from "../../../../../redux/actions/app/checkStatus/checkStatus.actions";
import {getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {LargeSpinner, loader} from "@amo/core/components/loaders";

const mapStateToProps = state => {
    return {
        id_devis: getIDDevisInDevisInit(state),
        etapes_paiement: getPaiementHistory(state),
        loaded: getLoadingEntity(state, CHECK_STATUS) === false,
        token: localStorageAMO && localStorageAMO.token,
        bodyCheck: {DemandeTarif: {Vehicule: {FamilleProduit: 'Trottinette'}}},
        contratState: getStateContract(state)
    }
}


const mapDispatchToProps = dispatch => bindActionCreators({
    fetchStatus: ({token,body}) => fetchStatus({token,body})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { fetchStatus } = dispatchProps
    const { token, bodyCheck } = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmitCheckStatus: () => fetchStatus({token: token, body: bodyCheck}),
        load: () => fetchStatus({token: token, body: bodyCheck})
    }
}


const PaiementEtapesContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({LoadingIndicator: LargeSpinner, ErrorIndicator: ErrorComponent})
)(PaiementEtapes)


export default PaiementEtapesContainer
