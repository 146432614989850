import {SET_SAVE_DEVIS} from "../../actions/app/save_devis/save_devis.actions";


export const saveDevisReducer = (devis = {}, action) => {
    const {payload} = action

    switch (action.type) {

        case SET_SAVE_DEVIS:
            return payload.data

        default:
            return devis

    }
}
