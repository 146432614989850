import {SET_CONTRACT} from "../../actions/app/contract/contract.actions";


export const contractReducer = (state = {}, action) => {
    const {payload} = action

    switch (action.type) {

        case SET_CONTRACT:
            return payload.data

        default:
            return state

    }
}
