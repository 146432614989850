import {NEW_DEVIS, SET_NEXT_PAGE, setMaxPage, UI} from "../../../actions/app/ui/ui.actions";
import {clearLocalStorage} from "../../../actions/core/localStorage/localStorage.actions";

export const uiMiddleware = () => next => action => {
    next(action)

    switch (action.type){

        case SET_NEXT_PAGE:
            const {step, maxStep} = action.payload

            if (step > maxStep){
                next([
                    setMaxPage({maxStep: step})
                ])
            }

            break

        case NEW_DEVIS:
            next([
                clearLocalStorage({entity: UI})
            ])

            window.location = '/'

            break

        default:
            return null
    }
}
