import {connect} from 'react-redux'
import Init from './Init'
import {bindActionCreators,compose} from "redux"
import {INIT, postInit} from "../../../redux/actions/app/init/init.actions";
import {getErrorEntity, getLoadingEntity} from "../../../redux/selectors/ui/ui.selectors";
import {localStorageAMO} from "../../../redux/selectors/localStorage/localStorage.selectors";
import ErrorComponent from "../Maintenance/Error/ErrorComponent";
import {APP_NAME} from '@amo/core/constants';
import {loader, LargeSpinner} from "@amo/core/components/loaders";

const mapStateToProps = (state, ownProps) => {

    return {
        loaded: getLoadingEntity(state, INIT) === false,
        erreur: getErrorEntity(state, INIT)
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    postInit: (key) => postInit({key}),
    load: () => postInit({
        key: window.location.pathname ?
            window.location.pathname?.slice(1)
            : JSON.parse(window.localStorage.getItem(`${APP_NAME}-source`)) && ((localStorageAMO && !localStorageAMO.token) || !localStorageAMO) ?
                JSON.parse(window.localStorage.getItem(`${APP_NAME}-source`)).key
                : null,
        token: localStorageAMO && localStorageAMO.token,
        body: {DemandeTarif: {Vehicule: {FamilleProduit: 'Trottinette'}}}
    })
}, dispatch)

const InitContainer = compose(
    connect(mapStateToProps, mapDispatchToProps),
    loader({LoadingIndicator: LargeSpinner, ErrorIndicator: ErrorComponent})
)(Init);

export default InitContainer
