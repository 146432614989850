import axios from 'axios'
import {API_REQUEST, apiError, apiSuccess} from '../../../actions/core/api/api.actions'
import {setMaintenance} from '../../../actions/app/ui/ui.actions'

export const apiMiddleware = ({dispatch}) => next => action => {
    next(action)

    if (action.type.includes(API_REQUEST)){
        const { meta: { url, method, entity, otherData }, data } = action.payload

        axios({method, url, data})
            .then (response => {
                dispatch(apiSuccess({response: response.data, entity, otherData}))
            })
            .catch(error => {
                if (error.response){
                    switch (error.response.status){

                        case 401:
                            dispatch(apiError({error, entity, otherData}))
                            break

                        case 503:
                            dispatch(apiError({error, entity, otherData}))
                            dispatch(setMaintenance({state: true, entity}))
                            break

                        default:
                            dispatch(apiError({error, entity, otherData}))
                    }
                } else {
                    dispatch(apiError({error, entity, otherData})) // OPTION REQUEST
                }
            })
    }
}
