import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import GetParams from "./GetParams";
import Maintenance from '@amo/core/pages/maintenance/Maintenance';

const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path='/maintenance' element={<Maintenance />} />
                <Route path='/:key?' element={<GetParams />} />
            </Routes>
        </BrowserRouter>
    )
}

export default App
