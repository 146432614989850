export const TARIF = '[Tarif]'

export const SET_TARIF = `${TARIF} Set`
export const POST_TARIF = `${TARIF} Post`

export const postTarif = ({query, step, form, ap1}) => ({
    type: POST_TARIF,
    payload: {
        data: query,
        body: form,
        meta:{
            otherData: {
                ap1
            }
        }
    },
    meta: {
        entity: TARIF
    }
})

export const setTarif = ({tarif}) => ({
    type: SET_TARIF,
    payload: {
        data: tarif
    }
})
