import {createSelector} from 'reselect'
import _ from 'lodash'

/* Contract */
const contractSelector = state => state.contract

export const getUrlPDFAttestationNvei = createSelector(
    contractSelector,
    contract => contract.UrlPDFAttestationNvei
)

export const getUrlPDFContrat = createSelector(
    contractSelector,
    contract => contract.UrlPDFContrat
)

export const getUrlPDFMandatSepa = createSelector(
    contractSelector,
    contract => contract.UrlPDFMandatSepa
)

export const getListeDocument = createSelector(
    contractSelector,
    contract => contract.ListeDocuments
)

export const getDocumentCI = createSelector(
    getListeDocument,
    list => _.find(list, ['Code', 'F'])
)

export const getPolice = createSelector(
    contractSelector,
    contract => contract.Police
)
