import {connect} from 'react-redux'
import Tarif from './Tarif'
import {bindActionCreators, compose} from "redux"
import {change, formValueSelector, reduxForm, getFormValues} from "redux-form";
import validate from './validate';
import {
    getNameWhitelabel,
    getSource,
    getGarantiesSouscriteOption,
    getIsDirect,
    getActeGestionDemandeTarifInit,
    getToken,
    getGarantiesOption, getCodeBelairApporteur1Init
} from "../../../../../../redux/selectors/init/init.selectors";
import {
    getFormuleF2, getUrlPDFDispositionsGenerales,
    getUrlPDFDocumentInformation, getFormuleF1, getTauxApporteurMaxiApporteurTarif
} from "../../../../../../redux/selectors/tarif/tarif.selectors";
import {setFormuleChoisie} from "../../../../../../redux/actions/app/ui/ui.actions";
import {
    getCodeFormuleChoice,
    getLoadingEntity,
    getMontantBaseFormuleChoice,
    getPrimeClientPCC1, getPrixTotalAnnuel, getStep
} from "../../../../../../redux/selectors/ui/ui.selectors";
import contenu from '../../../../../../constants/contenu_text'
import {FILL} from "../../../../../../redux/actions/app/fill/fill.actions";
import {postTarif} from "../../../../../../redux/actions/app/tarif/tarif.actions";
import {API_URL_WS_FORMULAIRE} from '@amo/core/constants';
import {loader} from "@amo/core/components/loaders";

const mapStateToProps = state => {
    const selector = formValueSelector('devis')
    const formuleChoisie = selector(state, 'DemandeTarif[Police][FormuleChoisie]');

    return {
        urlDG: getUrlPDFDispositionsGenerales(state),
        urlInfo: getUrlPDFDocumentInformation(state),
        formuleF1: getFormuleF1(state),
        formuleF2: getFormuleF2(state),
        comptantAnnuelFormule: getMontantBaseFormuleChoice(state),
        primePCC1: getPrimeClientPCC1(state),
        pccIsSelected: selector(state, 'DemandeTarif[ListeOptions][0][Souscrite]'),
        loading: getLoadingEntity(state, FILL),
        contenuText : contenu[getSource(state)],
        apporteurName: getNameWhitelabel(state),
        formuleSelectionnee: formuleChoisie,
        formuleSelectionneeLoad: getCodeFormuleChoice(state),
        garantiesSelected: getGarantiesSouscriteOption(state, formuleChoisie),
        garantiesSelectable: getGarantiesOption(state, formuleChoisie),
        isDirect: getIsDirect(state),
        isAvenant: getActeGestionDemandeTarifInit(state) === 'AV',
        tauxApporteurMax: getTauxApporteurMaxiApporteurTarif(state),
        honoraire: selector(state, 'DemandeTarif[Police][Honoraires]'),
        query: `${API_URL_WS_FORMULAIRE}/get_tarif/${getToken(state)}`,
        prixTotalAnnuel: getPrixTotalAnnuel(state),
        step: getStep(state),
        values: getFormValues('devis')(state),
        apporteur: getCodeBelairApporteur1Init(state),
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    postTarif,
    setFormule: (formule) => setFormuleChoisie({formule}),
    changeValue: (field, value) => change('devis', field, value)
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query,step, values, apporteur } = stateProps
    const { postTarif } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        reloadTarif: () => postTarif({query, step, form: values, ap1: apporteur}),
    }
}

const TarifContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'devis',
        destroyOnUnmount: false,
        validate
    }),
    loader()
)(Tarif)

export default TarifContainer
