import React, {useEffect} from 'react';
import {Field} from "redux-form";
import _ from "lodash";
import { Radio, Text, Select, DateField, Autocomplete } from '@amo/core/components/forms'
import {getCountries, maxDateToday, min14Year, required} from "@amo/core/utils";
import {QuestionLabel} from "@amo/core/components/containers";

const TiersPayeur = (props) => {
    const {changeValue, isAvenant, countries, setCountries, tiersPayeurRelationship, tiersPayeurType} = props

    useEffect(() => {
        if(_.isEmpty(countries)){
            (async function fetchCountries(){
                let countries = await getCountries()
                let countriesFormat

                countriesFormat = countries.map((country) => ({
                    value: country.id,
                    label: country.name,
                }));

                setCountries({countries: countriesFormat})
            })();
        }

        if(!tiersPayeurType){
            changeValue('DemandeContrat[TiersPayeur][Type]', 'P')
        }
    }, [])

    useEffect(() => {
        if(tiersPayeurType === 'M'){
            changeValue('DemandeContrat[TiersPayeur][BirthName]', '')
            changeValue('DemandeContrat[TiersPayeur][FirstName]', '')
            changeValue('DemandeContrat[TiersPayeur][BirthDate]', '')
            changeValue('DemandeContrat[TiersPayeur][BirthCountry]', '')
            changeValue('DemandeContrat[TiersPayeur][BirthCity]', '')
            changeValue('DemandeContrat[TiersPayeur][Relationship]', '')
            changeValue('DemandeContrat[TiersPayeur][ThirdPayerReason]', '')
        }
    }, [tiersPayeurType])

    useEffect(() => {
        if(tiersPayeurRelationship && tiersPayeurRelationship !== 'A'){
            changeValue('DemandeContrat[TiersPayeur][ThirdPayerReason]', '')
        }
    }, [tiersPayeurRelationship])

    return (
        <div>
            <hr/>
            <QuestionLabel className={'mt-4 d-block'}>Tiers payeur</QuestionLabel>

            <div className={'bg-secondary-light border p-4 rounded text-left my-5 f-14'}>
                <p className={'d-inline'}>La personne qui va payer les cotisations n'étant pas présente sur le contrat, nous avons besoin d'avoir ses informations personnelles.</p>
            </div>

            <QuestionLabel className={'f-16 mt-4'}>{tiersPayeurType?.tiersPayeurType === 'P' ? "Nom d'usage" : 'Nom'}{!isAvenant && <sup className={'text-danger'}>*</sup>}</QuestionLabel>
            <div className={'row'}>
                <div className={'col-12 col-md-6 mt-3 mt-md-6'}>
                    <Field
                        component={Text}
                        name={'DemandeContrat[TiersPayeur][LastName]'}
                        normalize={v => v.toUpperCase()}
                        validate={!isAvenant ? required : null}
                        maxLength={50}
                    />
                </div>
            </div>

            {tiersPayeurType === 'P' &&
                <>
                    <QuestionLabel className={'f-16 mt-4'}>Nom de naissance{!isAvenant && <sup className={'text-danger'}>*</sup>}</QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-12 col-md-6 mt-3 mt-md-6'}>
                            <Field
                                component={Text}
                                name={'DemandeContrat[TiersPayeur][BirthName]'}
                                normalize={v => v.toUpperCase()}
                                validate={!isAvenant ? required : null}
                                maxLength={20}
                            />
                        </div>
                    </div>

                    <QuestionLabel className={'f-16 mt-4'}>Prénom{!isAvenant && <sup className={'text-danger'}>*</sup>}</QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-12 col-md-6 mt-3 mt-md-6'}>
                            <Field
                                component={Text}
                                name={'DemandeContrat[TiersPayeur][FirstName]'}
                                normalize={v => v.toUpperCase()}
                                validate={!isAvenant ? required : null}
                                maxLength={20}
                            />
                        </div>
                    </div>

                    <QuestionLabel className={'f-16 mt-4'}>Date de naissance{!isAvenant && <sup className={'text-danger'}>*</sup>}</QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-12 col-md-6 mt-3 mt-md-6'}>
                            <Field
                                component={DateField}
                                name={'DemandeContrat[TiersPayeur][BirthDate]'}
                                label={'JJ/MM/AAAA'}
                                validate={!isAvenant ? [required, maxDateToday, min14Year] : [maxDateToday, min14Year]}
                                maxDate={new Date()}
                            />
                        </div>
                    </div>

                    <QuestionLabel className={'f-16 mt-4'}>Pays de naissance{!isAvenant && <sup className={'text-danger'}>*</sup>}</QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-12 col-md-6 mt-3 mt-md-6'}>
                            <Field
                                name={'DemandeContrat[TiersPayeur][BirthCountry]'}
                                component={Autocomplete}
                                data={countries}
                                validate={!isAvenant ? required : null}
                                disabled={false}
                            />
                        </div>
                    </div>

                    <QuestionLabel className={'f-16 mt-4'}>Ville de naissance{!isAvenant && <sup className={'text-danger'}>*</sup>}</QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-12 col-md-6 mt-3 mt-md-6'}>
                            <Field
                                name={'DemandeContrat[TiersPayeur][BirthCity]'}
                                component={Text}
                                validate={!isAvenant ? required : null}
                                maxLength={250}
                            />
                        </div>
                    </div>

                    <QuestionLabel className={'f-16 mt-4'}>Lien  souscripteur / payeur{!isAvenant && <sup className={'text-danger'}>*</sup>}</QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-12 col-md-6 mt-3 mt-md-6'}>
                            <Field
                                component={Select}
                                name={'DemandeContrat[TiersPayeur][Relationship]'}
                                validate={!isAvenant ? required : null}
                            >
                                <option>Sélectionnez...</option>
                                <option value="C">Conjoint</option>
                                <option value="E">Enfant</option>
                                <option value="FS">Frère/Sœur</option>
                                <option value="GP">Grand-parent</option>
                                <option value="P">Parent</option>
                                <option value="A">Autres</option>
                            </Field>
                        </div>
                    </div>

                    {
                        tiersPayeurRelationship && tiersPayeurRelationship === 'A' && <>
                            <QuestionLabel className={'f-16 mt-4'}>Motif d'intervention du payeur{!isAvenant && <sup className={'text-danger'}>*</sup>}</QuestionLabel>
                            <div className={'row'}>
                                <div className={'col-12 col-md-6 mt-3 mt-md-6'}>
                                    <Field
                                        name={'DemandeContrat[TiersPayeur][ThirdPayerReason]'}
                                        component={Text}
                                        validate={!isAvenant ? required : null}
                                        maxLength={250}
                                    />
                                </div>
                            </div>
                        </>
                    }
                </>
            }
        </div>
    );
};

export default TiersPayeur;
