import {connect} from 'react-redux'
import {bindActionCreators, compose} from "redux";
import Besoin from "./Besoin";
import {clearFields, formValueSelector, reduxForm} from "redux-form";
import validate from "../Informations/validate";
import {
    getActeGestionDemandeTarifInit,
    getApporteurCanUseRecueilBesoin,
    getSource,
} from "../../../../../redux/selectors/init/init.selectors";
import {getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {FILL} from "../../../../../redux/actions/app/fill/fill.actions";
import contenu from "../../../../../constants/contenu_text.json";

const mapStateToProps = state => {
    const selector = formValueSelector('devis')

    return {
        typeVehicule: selector(state, 'typeVehicule'),
        loading: getLoadingEntity(state, FILL),
        isCourtier: getSource(state) === 'courtier',
        isConseiller: getSource(state) === 'conseiller',
        canUseBesoin: getApporteurCanUseRecueilBesoin(state),
        recueilBesoinActif: selector(state, 'DemandeTarif[UseRecueilBesoin') === undefined ||  selector(state, 'DemandeTarif[UseRecueilBesoin') !== 'non',
        contenuText : contenu[getSource(state)],
        isAvenant: getActeGestionDemandeTarifInit(state) === 'AV'
    }
}


const mapDispatchToProps = dispatch => bindActionCreators({
    unsetValue: (field) => clearFields('devis', false, false, field),
}, dispatch)

const BesoinContainer = compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'devis',
        destroyOnUnmount: false,
        validate
    })
)(Besoin)

export default BesoinContainer
