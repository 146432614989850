import _ from 'lodash'

const validate = values => {
    const errors = {}

    const requiredFields = [
        'DemandeTarif[Police][FormuleChoisie]'
    ]

    requiredFields.forEach(field => {
        if (!_.get(values, field)) {
            _.set(errors, field, 'Champ obligatoire')
        }
    })

    return errors
}

export default validate
