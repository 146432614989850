import React from 'react';
import {FormErrorStyled, FormErrorText} from "./ErrorStyled.jsx";
import PropTypes from 'prop-types';

const ErrorComponent = ({children}) => {
    return (
        <FormErrorStyled>
            <span className="icon-warning icon" />
            <FormErrorText>{children}</FormErrorText>
        </FormErrorStyled>
    );
};

ErrorComponent.propTypes = {
    children: PropTypes.node
};

ErrorComponent.defautValue = {
    children: "Veuillez entrer une entrée valide"
};

export default ErrorComponent;