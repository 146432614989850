export const FILL = '[Fill]'

export const POST_FILL = `${FILL} Post`

export const postFill = ({acteGestion, step, maxStep, ap1, form, query}) => ({
    type: POST_FILL,
    payload: {
        data: query,
        body: form,
        meta:{
            otherData: {
                step,
                maxStep,
                acteGestion,
                ap1
            }
        }
    }
})
