import {SET_LINKS} from '../../actions/app/links/links.actions'

export const linksReducer = (links = {}, action) => {
    const {payload} = action

    switch (action.type) {

        case SET_LINKS:
            return payload.data

        default:
            return links

    }
}
