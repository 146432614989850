import React, {useEffect, useState} from 'react'
import './signature.scss'
import {Field} from "redux-form";
import {normalizeTel} from "@amo/core/utils";
import {Text} from "@amo/core/components/forms";

const Signature = (props) => {
    const { source, sign_url, phone, contenuText, signature, whitelabelName, codeProduit, libelle, priceFormule, pcc, pricePCC, police, amount} = props
    const [phoneSign, setPhoneSign] = useState(phone)
    const [recieptBySms, setRecieptBySms] = useState(true)

    useEffect(() => {
        let products = [{
            sku: codeProduit,
            name: libelle,
            category: 'formule',
            price: priceFormule,
            quantity: '1'
        }]

        if (pcc){
            products.push({
                name: pcc.CodeGarantie,
                price: pricePCC,
                category: 'option',
                quantity: '1'
            })
        }
    }, []);

    const setSignaturePhone = value => {
        let newValue = value

        if (value.replace(/\s+/g, "").length > 10) {
            newValue = value.substring(0, value.length - 1)
        }

        setPhoneSign(newValue.replace(/\s+/g, ""))
    }

    return (
        <div className={'steps-formulaire'}>
            <div className={'container'}>
                <div className={'row'}>
                    <div className={'col'}>
                        <div className={'bg-secondary-light p-4 mb-5 blocSuccess text-center rounded'}>
                            <div className={'f-21 textCheck text-primary-light mb-3'}>
                                <span className={`icon-Shape p-2 border full-rounded border-primary-light d-inline-block position-relative align-middle mr-2`} style={{fontSize: 7}} />
                                {contenuText.valide_paiement}
                            </div>
                            <div className={'f-16 mb-5'}>
                                {contenuText.valide_paiement_justificatif}
                            </div>

                            <div className={'mb-5'}>
                                <p className={'f-21'}>{contenuText.text_sign}</p>
                                <p>{contenuText.content_sign}</p>
                            </div>
                            {source === 'site' &&
                            <div className={'f-14 col-md-6 mt-md-4 offset-md-3'}>
                                <strong dangerouslySetInnerHTML={{__html:contenuText.code_send_to }} />
                                {signature.started === true && <span style={{fontWeight: 'bold', color: 'black', fontSize: '24px', marginLeft: '5px'}}>{phoneSign}</span>}
                                {signature.started === false &&
                                    <Field
                                        component={Text}
                                        name={'DemandeContrat[SignaturePhone]'}
                                        placeholder={'Exemple : 0606060606'}
                                        normalize={normalizeTel}
                                        onChange={(e) => setSignaturePhone(e.target.value)}
                                        icon={'phone'}
                                        iconSize={25}
                                    />
                                }
                            </div>
                            }
                            {source === 'site' && <div className={'col-md-6 offset-md-3 mt-5'}>
                                <a href={`${sign_url}&method=${recieptBySms ? 'sms' : 'email'}&customer_${recieptBySms ? 'phone' : 'email'}=${phoneSign}`} className={"btn btn-block btn-primary medium-rounded text-uppercase mb-2"}>
                                    <span className={`icon-security `} style={{fontSize: 18, margin: '0 10px'}} />Je signe mes documents avec YouSign<br/>
                                </a>
                            </div>}
                        </div>

                        <div className={'text-center mt-3 mb-5'}>
                            <div className={'row f-14 mb-5 blocIcons'}>
                                <div className={'col-12 col-md-4'}>
                                    <span className={`icon-document p-2 border full-rounded border-primary-light d-inline-block position-relative align-middle mb-2`} style={{fontSize: 50}} />
                                    <div className={'fw-bold'}>Je prends connaissance des documents et je les signe</div>
                                </div>
                                <div className={'col-12 col-md-4'}>
                                    <span className={`icon-chat p-2 border full-rounded border-primary-light d-inline-block position-relative align-middle mb-2`} style={{fontSize: 40}} />
                                    <div className={'fw-bold'}>Je reçois un code par SMS ou par email pour valider la signature</div>
                                </div>
                                <div className={'col-12 col-md-4'}>
                                    <span className={`icon-download p-2 border full-rounded border-primary-light d-inline-block position-relative align-middle mb-2`} style={{fontSize: 35}} />
                                    <div className={'fw-bold'}>Je reçois les documents signés par email et j'envoie mes pièces justificatives</div>
                                </div>
                            </div>
                        </div>

                        <div className={'bg-grey p-4 rounded text-left'}>
                            <strong className={'text-uppercase mb-2 d-block'}>Qu'est ce que la signature électronique ?</strong>
                            <p>La signature électronique est, pour un document numérique, l’équivalent de la signature manuscrite. En vertu du décret n°2001-272 du 30 mars 2001, elle a la même valeur légale qu’une signature manuscrite.</p>
                            <p>Le client signe numériquement son contrat de manière totalement sécurisée et il est garanti par un tiers de confiance.</p>
                            <p>{whitelabelName} a fait confiance à YouSign pour la signature électronique. Ce dernier propose un service certifié conforme aux normes et standards européens, qui permet d'authentifier l’ensemble des auteurs.</p>
                            <strong className={'mt-4 d-block'}>Comment ça marche ? Rien de plus simple :</strong>
                            <ol>
                                <li>Cliquez sur le bouton “Je signe et je télécharge mes documents”</li>
                                <li>Prenez connaissance de l’ensemble des documents et cliquez sur le bouton “Valider et lancer la signature des fichiers”</li>
                                <li>Saisissez le code reçu par SMS</li>
                                <li>Téléchargez vos documents contractuels signés et conservez-les précieusement</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Signature
