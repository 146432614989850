import {POST_TARIF, TARIF, setTarif} from '../../../actions/app/tarif/tarif.actions'
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setFormuleChoisie, setLoader} from '../../../actions/app/ui/ui.actions'
import _ from "lodash";
import {setDemandeTarifAfterGetTarif} from "../../../actions/app/init/init.actions";
import {formatDataForApi} from "../fill/formatDataForApi";
import {analyticsSetEvent} from "../../../actions/core/analytics/analytics.actions.js";

export const tarifMiddleware = () => next => action => {
    next(action)

    const { payload } = action

    switch (action.type){

        case POST_TARIF:
            let body = {}

            if (payload.body){
                body = formatDataForApi(payload.body)
            }
            //Pour le tarif pas de mise à jour ListePersonnes
            body = _.omit(body, 'DemandeTarif[ListePersonnes]')

            next([
                apiRequest({body, method: 'POST', url: payload.data, entity: TARIF, otherData: payload?.meta?.otherData}),
                setLoader({state: true, entity: TARIF})
            ])
            break

        case `${TARIF} ${API_SUCCESS}`:
            const {data} = payload.data

            const formule = _.find(data.Tarif.ListeFormules, ['CodeFormule', data.DemandeTarif.Police.FormuleChoisie || data.Tarif.FormulePreconisee || 'F1'])

            next([
                setTarif({tarif: payload.data.data.Tarif}),
                setDemandeTarifAfterGetTarif({data: payload.data.data.DemandeTarif}),
                setFormuleChoisie({formule}),
                setError({state: false, entity: TARIF}),
                setLoader({state: false, entity: TARIF})
            ])
            break

        case `${TARIF} ${API_ERROR}`:
            next([
                analyticsSetEvent({event: 'errorMessage', ap1: payload?.meta?.otherData?.ap1, datas: {
                    'errorMessage': {
                        'label': payload?.data?.response?.data?.error || '',
                    }
                }}),
                setError({state: true, entity: TARIF}),
                setLoader({state: false, entity: TARIF})
            ])

            window.location = '/maintenance'
            break

        default:
            return null
    }
}
