import {connect} from 'react-redux';
import AnnulationPaiement from './AnnulationPaiement';
import {API_URL_WS_FORMULAIRE, APP_URL_APPLICATION} from '@amo/core/constants';
import {getSource, getToken} from "../../../../../redux/selectors/init/init.selectors";
import {bindActionCreators} from "redux";
import {newDevis} from "../../../../../redux/actions/app/ui/ui.actions";

const mapStateToProps = state => {
    return {
        url_pay: {
            uri: `${API_URL_WS_FORMULAIRE}/pay/${getToken(state)}`,
            token: getToken(state),
            url_return: APP_URL_APPLICATION
        },
        source: getSource(state)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    myNewDevis: () => newDevis()
}, dispatch);

const AnnulationPaiementContainer = connect(mapStateToProps, mapDispatchToProps)(AnnulationPaiement);

export default AnnulationPaiementContainer
