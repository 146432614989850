import {connect} from 'react-redux'
import Signature from './Signature'
import contenu from "../../../../../constants/contenu_text";
import {
    getEmailSouscripteur,
    getSource,
    getTelPortableSouscripteur,
    getSignature, getAmount, getPCCInDevisInit, getUrlSignaturesInit, getNameWhitelabel
} from "../../../../../redux/selectors/init/init.selectors";
import {getPolice} from "../../../../../redux/selectors/contract/contract.selectors";
import {
    getLibelleFormuleChoice,
    getMontantBaseFormuleChoice,
    getPrimeClientPCC1
} from "../../../../../redux/selectors/ui/ui.selectors";
import {getCodeProduit} from "../../../../../redux/selectors/tarif/tarif.selectors";
import {APP_URL_APPLICATION} from '@amo/core/constants';

const mapStateToProps = state => {
    return {
        sign_url: getSource(state) === 'site' ? `${getUrlSignaturesInit(state)}?url_return=${APP_URL_APPLICATION}` : null,
        contenuText : contenu[getSource(state)],
        source: getSource(state),
        phone: getTelPortableSouscripteur(state),
        email: getEmailSouscripteur(state),
        signature: getSignature(state),
        police: getPolice(state),
        amount: getAmount(state),
        libelle: getLibelleFormuleChoice(state),
        pcc: getPCCInDevisInit(state),
        codeProduit: getCodeProduit(state),
        pricePCC: getPrimeClientPCC1(state),
        priceFormule: getMontantBaseFormuleChoice(state),
        whitelabelName: getNameWhitelabel(state)
    }
}

const SignatureContainer = connect(mapStateToProps)(Signature)

export default SignatureContainer
