import { Avatar } from '@amo/core/components/containers';
import avatar from '../../../../../assets/images/avatar.png';
import './bulle.scss';

const BulleAide = (props) => {
    const { etape, contenuText, formule } = props;

    return (
        <div className={`bulle-aide`}>
            <div className={'bulle-content bg-secondary-light'}>
                <h2 className={'title'}>Nos conseils</h2>
                {etape === '0' && (
                    <>
                        <h2 className={'color'}>La trottinette</h2>
                        <p dangerouslySetInnerHTML={{ __html: contenuText.trottinette_homologue1 }} />
                        <br />
                        <p
                            className={'color-text'}
                            dangerouslySetInnerHTML={{ __html: contenuText.trottinette_homologue1_green }}
                        />
                        <br />
                        <p className={'warning'}>{contenuText.trottinette_transport}</p>
                    </>
                )}
                {etape === '1' && (
                    <>
                        <h2 className={'color'}>{contenuText.titre_info}</h2>
                        <p dangerouslySetInnerHTML={{ __html: contenuText.info_perso }} />

                        {formule === 'F2' && (
                            <>
                                <h2 className={'color mt-3'}>ASSR2</h2>
                                <p dangerouslySetInnerHTML={{ __html: contenuText.assr2_text }} />
                                <br />
                                <p
                                    className={'color-text'}
                                    dangerouslySetInnerHTML={{ __html: contenuText.assr2_green }}
                                />
                            </>
                        )}
                    </>
                )}
                <Avatar img={avatar} direction={'top'} />
            </div>
        </div>
    );
};
BulleAide.defaultProps = {
    formule: '',
};

export default BulleAide;
