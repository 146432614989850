import {uiMiddleware} from './ui/ui.middleware'
import {linksMiddleware} from "./links/links.middleware";
import {initMiddleware} from "./init/init.middleware";
import {fillMiddleware} from "./fill/fill.middleware";
import {tarifMiddleware} from "./tarif/tarif.middleware";
import {saveDevisMiddleware} from "./save_devis/save_devis.middleware";;
import {checkStatusMiddleware} from "./checkStatus/checkStatus.middleware";
import {demandeRappelMiddleware} from "./demandeRappel/demandeRappel.middleware";


export const appMiddleware = [
    uiMiddleware,
    linksMiddleware,
    initMiddleware,
    fillMiddleware,
    tarifMiddleware,
    saveDevisMiddleware,
    checkStatusMiddleware,
    demandeRappelMiddleware
]
