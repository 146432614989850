export const UI = '[Ui]'

export const SET_LOADER = `${UI} SET_LOADER`
export const SET_ERROR = `${UI} SET_ERROR`
export const SET_MAINTENANCE = `${UI} SET_MAINTENANCE`
export const SET_NEXT_PAGE = `${UI} SET_NEXT_PAGE`
export const SET_MAX_PAGE = `${UI} SET_MAX_PAGE`
export const SET_PREVIOUS_PAGE = `${UI} SET_PREVIOUS_PAGE`
export const SET_STEP = `${UI} SET_STEP`
export const SET_FORMULE = `${UI} SET_FORMULE`
export const GET_PROPOSITION = `${UI} GET_PROPOSITION`
export const SET_GO_TO_PAY = `${UI} SET_GO_TO_PAY`
export const SET_UPLOAD = `${UI} SET_UPLOAD`
export const NEW_DEVIS = `${UI} NEW_DEVIS`
export const SET_COUNTRIES = `${UI} SET_COUNTRIES`

export const setLoader = ({state, entity}) => ({
    type: `${entity} ${SET_LOADER}`,
    payload: state,
    meta: entity
})

export const setError = ({state, entity}) => ({
    type: `${entity} ${SET_ERROR}`,
    payload: state,
    meta: entity
})

export const setMaintenance = ({state, entity}) => ({
    type: `${entity} ${SET_MAINTENANCE}`,
    payload: state
})

export const setNextPage = ({step, maxStep}) => ({
    type: SET_NEXT_PAGE,
    payload: {
        step,
        maxStep
    },
    meta: {
        entity: UI
    }
})

export const setMaxPage = ({maxStep}) => ({
    type: SET_MAX_PAGE,
    payload: maxStep
})

export const setStep = ({step}) => ({
    type: SET_STEP,
    payload: step,
    meta: {
        entity: UI
    }
})

export const setFormuleChoisie = ({formule}) => ({
    type: SET_FORMULE,
    payload: formule,
    meta: {
        entity: UI
    }
})

export const getProposition = ({form}) => ({
    type: GET_PROPOSITION,
    payload: {
        form
    }
})

export const setGoToPay = ({state}) => ({
    type: SET_GO_TO_PAY,
    payload: state
})

export const setUpload = ({entity, value, formName}) => ({
    type: `${entity} ${SET_UPLOAD}`,
    payload: {
        value,
        formName
    }
})

export const newDevis = () => ({
    type: NEW_DEVIS,
    payload: NEW_DEVIS
})

export const setCountries = ({countries}) => ({
    type: SET_COUNTRIES,
    payload: countries
})