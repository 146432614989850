import React from 'react';
import TarifContainer from './Tarif/TarifContainer'

const TarifLoad = (props) => {
    return (
        <TarifContainer {...props} />
    )
}

export default TarifLoad
