import {useEffect, useState} from 'react'
import { Field } from "redux-form";
import './tarif.scss'
import _ from "lodash";
import {required} from "@amo/core/utils/validateField.js";
import {Radio, Checkbox} from "@amo/core/components/forms";
import Trottinette from "@amo/core/assets/images/produits/trottinette.svg"
import Insurance from "@amo/core/assets/images/icons/insurance.svg"
import {QuestionLabel, Title} from "@amo/core/components/containers";
import Tips from "@amo/core/components/containers/Tips/Tips.jsx";
import SlideContainer from "../../Slide/SlideContainer.js";

const Tarif = (props) => {
    const {loading, handleSubmit, invalid, submitting, urlDG, urlInfo, primePCC1, pccIsSelected, formuleF1, formuleF2,
        setFormule, contenuText, changeValue, garantiesSelected, garantiesSelectable, apporteurName,
        formuleSelectionnee, formuleSelectionneeLoad, isAvenant, isDirect, tauxApporteurMax, honoraire, reloadTarif,
        prixTotalAnnuel} = props
    const [premierAffichage, setPremierAffichage] = useState(true);

    useEffect(() => {
        if(formuleSelectionneeLoad && !formuleSelectionnee){
            changeValue('DemandeTarif[Police][FormuleChoisie]', formuleSelectionneeLoad);
        }
    }, [formuleSelectionneeLoad, changeValue, formuleSelectionnee])

    useEffect(() => {
        // A l'affichage de la page on parcours toutes les options dispos
        // On met à jour le champ souscrite en fonction de s'il est sélectionné ou non
        if(premierAffichage && garantiesSelected && formuleSelectionnee){
            _.map(garantiesSelectable, g => {
                changeValue(`DemandeTarif[ListeOptions][0][Souscrite]`, !!_.find(garantiesSelected, { CodeGarantie: g.CodeGarantie}));
            });
            setPremierAffichage(false);
        }
    }, [garantiesSelected])

    return (
        <form onSubmit={handleSubmit} className='d-md-block d-lg-block'>
            <div className={'container'}>
                <div className={'row'}>
                    <div className={'col mb-4'}>
                        <Title className={'text-center f-18'} dangerouslySetInnerHTML={{__html: contenuText.title_formules }}>
                            <Tips size={'small'}>1</Tips>
                        </Title>
                    </div>
                </div>
                <div className={'mb-5'}>
                    <div className={'row'}>
                        <div className={'col-lg-6 col-md-6 col-sm-6 col-xs-12 text-center'}>
                            <Field
                                name={'DemandeTarif[Police][FormuleChoisie]'}
                                value={'F1'}
                                component={Radio}
                                type={'radio'}
                                label={`<b>${contenuText.formule_solo}</b><p>${contenuText.formule_solo_contenu}</p>`}
                                icon={Trottinette}
                                onChange={() => setFormule(formuleF1)}
                            />
                        </div>
                        <div className={'col-lg-6 col-md-6 col-sm-6 col-xs-12 text-center'}>
                            <Field
                                name={'DemandeTarif[Police][FormuleChoisie]'}
                                value={'F2'}
                                component={Radio}
                                type={'radio'}
                                label={`<b>${contenuText.formule_famille}</b><p>${contenuText.formule_famille_contenu}</p>`}
                                icon={Insurance}
                                onChange={() => setFormule(formuleF2)}
                            />
                        </div>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col'}>
                        <h1 className={'h4 text-center mt-5 mb-3'}>{contenuText.garanties_title}</h1>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col mb-5'}>
                        <div className={'border content-garanties'}>
                            <h3 className={'h5 text-center mt-4 mb-5'}>{contenuText.garanties_sub_title}</h3>
                            <div className={'liste-garanties'}>
                                <Title dangerouslySetInnerHTML={{__html: contenuText.garantie_1_title }}>
                                    <Tips size={'very-small'}>&nbsp;</Tips>
                                </Title>
                                <p className={'subtitle'}>{contenuText.garantie_1 }</p>
                                <br/>
                                <Title dangerouslySetInnerHTML={{__html: contenuText.garantie_2_title }}>
                                    <Tips size={'very-small'}>&nbsp;</Tips>
                                </Title>
                                <p className={'subtitle'}>{contenuText.garantie_2 }</p>
                                <br/>
                            </div>

                            <div className={'px-4 py-3 bg-secondary-light f-12 text-center plus-amo'}>
                                <div
                                    className={'text-uppercase text-primary fw-bold mb-3 d-flex align-items-center justify-content-center'}>
                                    Le + {apporteurName} :
                                </div>
                                <div className={'text-primary-light f-18 fw-bold'}>{contenuText.garantie_3_title }</div>
                                <p className={'f-14'} dangerouslySetInnerHTML={{__html: contenuText.garantie_3 }} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col'}>
                        <Title className={'mt-5 mb-2 text-center f-18'} dangerouslySetInnerHTML={{__html: contenuText.reco_option }}>
                            <Tips size={'small'}>2</Tips>
                        </Title>
                        <p className={'subtitle-reco mb-4 text-center'} dangerouslySetInnerHTML={{__html: contenuText.reco_option_description }}/>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col-12 py-4 bg-grey'}>
                        <div className="row">
                            <div className="col">
                                <Field
                                    name={'DemandeTarif[ListeOptions][0][Souscrite]'}
                                    component={Checkbox}
                                    type={'checkbox'}
                                    tarif={primePCC1}
                                >
                                    <QuestionLabel className={'f-16 mt-0 ml-2 cursor-pointer'}>{contenuText.pcc_title+'*'}</QuestionLabel>
                                </Field>
                            </div>
                            <div className="col-1">
                                {primePCC1 && <div className={'tarif-bulle'}>+{primePCC1}€</div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <small className={"float-right"}>*{contenuText.pcc_content}</small>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col'}>
                        <Title className={'mt-5 mb-3 text-center f-18'} dangerouslySetInnerHTML={{__html: contenuText.validation_premiere_etape }}>
                            <Tips size={'small'}>3</Tips>
                        </Title>
                    </div>
                </div>
                <div className={`row headerTarifContent ${!isDirect && !isAvenant ? 'mb-5' : ''}`}>
                    <div className={'col-12 col-lg-6 offset-lg-3 text-center'}>
                        <div className={'blocTarif rounded border border-primary-light'}>
                            <h2>{contenuText.tarif_title}</h2>

                            <div className={'fw-bold prix m-0 '}>
                                <div className={'d-block fw-bold text-primary mb-0'}>
                                    {prixTotalAnnuel}€ <br/>
                                    <span className="text-initial">TTC/Pour 1 an</span>
                                </div>
                            </div>
                            <div className={'fw-bold mt-3'}>
                                Formule Trottinette
                                { pccIsSelected ? <span> + 1 option</span> : '' }
                            </div>
                        </div>
                    </div>
                </div>

                { !isDirect && !isAvenant &&
                    <>
                        <div className={'row'}>
                            <div className={'col'}>
                                <Title className={'mt-5 mb-3 text-center f-18'} dangerouslySetInnerHTML={{__html: 'Vos frais de dossier' }}>
                                    <Tips size={'small'}>4</Tips>
                                </Title>
                            </div>
                        </div>

                        <p className="h5 text-center mt-5 mb-3">Montant des frais de dossier : <b>{honoraire !== undefined && `${honoraire} €`}</b></p>
                        <Field
                            name={'DemandeTarif[Police][Honoraires]'}
                            component={SlideContainer}
                            step={1}
                            min={0}
                            max={tauxApporteurMax}
                            validate={required}
                            maxBackground={'bg-success'}
                            minBackground={'bg-success'}
                            minColor={'text-white'}
                            maxColor={'text-white'}
                            symbole={'€'}
                        />

                        <div className={'mt-3 text-center'}>
                            <button className={`btn btn-link`} onClick={reloadTarif} data-id="btn-devis">Recalculer le tarif</button>
                        </div>
                    </>
                }

                <div className={'row'}>
                    <div className={'col text-center'}>
                        <div className={'mt-5 submit-tarif text-center'}>
                            <button className={`btn btn-primary medium-rounded`} disabled={invalid || submitting || loading}>Souscrire</button>
                        </div>
                    </div>
                </div>

                <div className={'row'}>
                    <div className={'col'}>
                        <p className={'text-center f-14 mt-4'}>Consultez les <a href={urlDG} target={'_blank'}>Conditions générales</a> et le <a href={urlInfo} target={'_blank'}>Document d'information</a> pour voir le détail de des garanties.</p>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default Tarif
