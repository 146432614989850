import {connect} from 'react-redux'
import {compose} from 'redux'
import {
    getCodeBelairApporteur1Init,
    getIsDirect,
    getIsMB,
    getLogoWhitelabel, getSource
} from "../../../../../redux/selectors/init/init.selectors";
import {getStep} from "../../../../../redux/selectors/ui/ui.selectors.js";
import {TopBar} from "@amo/core/components/containers";

const mapStateToProps = state => {
    return {
        logo: getLogoWhitelabel(state),
        isDirect: getIsDirect(state),
        source: getSource(state),
        isMB: getIsMB(state),
        step: getStep(state),
        apporteur: getCodeBelairApporteur1Init(state)
    }
}

const TopBarContainer = compose(
    connect(mapStateToProps),
)(TopBar)

export default TopBarContainer
