import _ from 'lodash'

const validate = values => {
    const errors = {}

    if (_.get(values, 'DemandeTarif[ListePersonnes][0][Email]') === _.get(values, 'DemandeDevis[EmailCourtier]')){
        _.set(errors, 'DemandeDevis[EmailCourtier]', 'Cette adresse email est déjà utilisée pour la souscription.')
    }

    if (values.typeVehicule === 'non') {
        errors.typeVehicule = true
    }

    return errors
}

export default validate
