import {connect} from 'react-redux'
import TarifLoad from './TarifLoad'
import {bindActionCreators, compose} from "redux"
import {getFormValues} from "redux-form";
import {getLoadingEntity, getStep} from "../../../../../redux/selectors/ui/ui.selectors";
import {API_URL_WS_FORMULAIRE} from '@amo/core/constants';
import {getCodeBelairApporteur1Init, getToken} from "../../../../../redux/selectors/init/init.selectors";
import {postTarif, TARIF} from "../../../../../redux/actions/app/tarif/tarif.actions";
import {loader, SmallSpinner} from "@amo/core/components/loaders";

const mapStateToProps = state => {

    return {
        token: getToken(state),
        loaded: getLoadingEntity(state, TARIF) === false,
        step: getStep(state),
        values: getFormValues('devis')(state),
        apporteur: getCodeBelairApporteur1Init(state),
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    postGetTarif: ({query, step, form, ap1}) => postTarif({query, step, form, ap1}),
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { postGetTarif } = dispatchProps
    const { token, step, values, apporteur } = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => postGetTarif({
            query: `${API_URL_WS_FORMULAIRE}/get_tarif/${token}`,
            step,
            form: values,
            ap1: apporteur
        })
    }
}

const TarifLoadContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({LoadingIndicator: SmallSpinner})
)(TarifLoad)

export default TarifLoadContainer
