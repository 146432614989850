import React, {Fragment, Component} from 'react';
import _ from 'lodash'
import { Field } from 'redux-form'
import BulleAide from "../BulleAide/BulleAide";
import { Radio, Text, DateField, PostalCodeCity, AutoCompleteBirth, Autocomplete, CAPAdresseTrottinetteVae } from '@amo/core/components/forms'
import './informations.scss'
import {ChampsObligatoires, QuestionLabel, Title} from '@amo/core/components/containers'
import {
    excludeCodePostal,
    maxDateToday,
    min18Year, onError,
    required,
    validateEmail, validatePhone
} from '@amo/core/utils/validateField.js';
import {getAddress, getCitiesFromFrance, getCountries} from "@amo/core/utils/requestsApi.js";
import {normalizeTel} from "@amo/core/utils/normalize.js";
import {SmallSpinner} from "@amo/core/components/loaders";

class Informations extends Component {
    constructor (props) {
        super(props)
        this.state = {
            showMore: false,
            goToPay: false,
            errorIban: null,
            loadingIban: false,
            addressSelected: null,
            listAddresses: [],
            hasSearchAddress: false,
            capbirthIsDown: false
        }
    }

    componentWillUnmount() {
        if (this.props.cpIBAN === undefined || this.props.villeIBAN === undefined){
            this.props.changeValue('DemandeContrat[cpIBAN]', this.props.cpConducteur)
            this.props.changeValue('DemandeContrat[villeIBAN]', this.props.villeConducteur)
            this.props.changeValue('postalCodeCity-postalcode-iban', true)
        }
    }

    async componentDidMount() {
        this.props.changeValue('DemandeTarif[Police][TauxApporteur1]', this.props.tauxApporteur1)
        this.props.changeValue('emailOnError', this.props.email && this.props.emailValidation && this.props.email!==this.props.emailValidation ? 'onError' : null);

        let countries = await getCountries();
        let countriesFormat

        countriesFormat = countries.map((country) => ({
            value: country.id,
            label: country.name,
        }));

        this.props.setCountries({countries: countriesFormat});
    }

    render(){
        const { data, postalCodeCity, adresseNew, addressNotFound, loading, previousPage, invalid, submitting, handleSubmit, formule, source,
            isAvenant, goToPay, contenuText, libelle, changeValue, nomConducteur, prenomConducteur, whitelabelName,
            emailDPOWhitelabel, urlSiteWhitelabel, gestionnaireIsAMO,
            emailOnError, email, emailValidation, countries, birthCity, birthCountry} = this.props

        const fetchAddress = async (value) => {
            let address = await getAddress(value)
            this.setState({
                addressSelected: null,
                listAddresses: address,
                hasSearchAddress: true
            })
        }

        const setErrorLoadingCountries = (bool) => {
            this.setState({
                errorLoadingCountries: bool
            })
        }

        const postSubmit = (values, destination) => {
            this.props.onSubmit({
                ...values,
                destination: destination
            })

            if(this.state.hasSearchAddress){
                getAddress(_.get(values, 'DemandeTarif[ListePersonnes][0][Adresse1]'), "ValidateForm")
            }

            if(birthCountry === 'FRA' && birthCity) {
                getCitiesFromFrance(birthCity, 'ValidateFormBirth')
            }
        }

        const setHasSearchAddress = () => {
            this.setState({
                hasSearchAddress: true
            })
        }

        if (goToPay){
            if (!this.state.goToPay){
                this.buttonElement.click()
                this.setState({
                    goToPay
                })
            }
        }

        return (
            <div className={'container steps-formulaire'}>
                <form onSubmit={handleSubmit} className='row'>
                    <div className={'col-lg-8'} id={'informations'}>
                        <Title icon={'work'} dangerouslySetInnerHTML={{__html: contenuText.bloc_infos }} />
                        <QuestionLabel className={'f-16 mt-4'}>Civilité<sup className={'text-danger'}>*</sup></QuestionLabel>
                        <div className={'content-radio'}>
                            <Field
                                name={'DemandeTarif[ListePersonnes][0][Civilite]'}
                                component={Radio}
                                className={"small"}
                                type={'radio'}
                                label={'Madame'}
                                value={'1'}
                            />
                            <span style={{margin: '0 5px'}}/>
                            <Field
                                name={'DemandeTarif[ListePersonnes][0][Civilite]'}
                                component={Radio}
                                className={"small"}
                                type={'radio'}
                                label={'Monsieur'}
                                value={'0'}
                            />
                        </div>

                        <QuestionLabel className={'f-16 mt-4'}>Nom d'usage<sup className={'text-danger'}>*</sup></QuestionLabel>
                        <div className={'row'}>
                            <div className={'col-12 col-md-6 mt-3 mt-md-4'}>
                                <Field
                                    name={'DemandeTarif[ListePersonnes][0][Nom]'}
                                    component={Text}
                                    normalize={v => v.toUpperCase()}
                                    validate={required}
                                    onChange={(e) => {
                                        changeValue('DemandeContrat[NomTitulaire]', `${e.target.value ? e.target.value.toUpperCase() : ''} ${prenomConducteur ? prenomConducteur : ''}`);
                                    }}
                                />
                            </div>
                        </div>

                        <QuestionLabel className={'f-16 mt-4'}>Nom de naissance{!isAvenant && <sup className={'text-danger'}>*</sup>}</QuestionLabel>
                        <div className={'row'}>
                            <div className={'col-12 col-md-6 mt-3 mt-md-4'}>
                                <Field
                                    name={'DemandeTarif[ListePersonnes][0][BirthName]'}
                                    component={Text}
                                    normalize={v => v.toUpperCase()}
                                    validate={!isAvenant ? required : null}
                                />
                            </div>
                        </div>

                        <QuestionLabel className={'f-16 mt-4'}>Prénom<sup className={'text-danger'}>*</sup></QuestionLabel>
                        <div className={'row'}>
                            <div className={'col-12 col-md-6 mt-3 mt-md-4'}>
                                <Field
                                    name={'DemandeTarif[ListePersonnes][0][Prenom]'}
                                    component={Text}
                                    normalize={v => v.toUpperCase()}
                                    validate={required}
                                    onChange={(e) => {
                                        changeValue('DemandeContrat[NomTitulaire]', `${nomConducteur ? nomConducteur : ''} ${e.target.value ? e.target.value.toUpperCase() : ''}`);
                                    }}
                                />
                            </div>
                        </div>

                        {
                            (
                                data?.DemandeTarif?.ListePersonnes[0]?.Prenom &&
                                (
                                    data?.DemandeTarif?.ListePersonnes[0]?.Nom === data?.DemandeTarif?.ListePersonnes[0]?.Prenom ||
                                    data?.DemandeTarif?.ListePersonnes[0]?.BirthName === data?.DemandeTarif?.ListePersonnes[0]?.Prenom
                                )
                            ) &&
                            <div className={'row'}>
                                <div className={'col-12 col-md-6 mt-0'}>
                                    <div className={'alert alert-warning f-14 mt-4 text-center'}>
                                        <p className={'mb-0'}>Votre nom et prénom sont identiques.<br/>Merci de vérifier vos informations avant de continuer.</p>
                                    </div>
                                </div>
                            </div>
                        }

                        <QuestionLabel className={'f-16 mt-4'}>Date de naissance<sup className={'text-danger'}>*</sup></QuestionLabel>
                        <div className={'row'}>
                            <div className={'col-12 col-md-6 mt-3 mt-md-4'}>
                                <Field
                                    name={'DemandeTarif[ListePersonnes][0][DateNaissance]'}
                                    component={DateField}
                                    label={'JJ/MM/AAAA'}
                                    validate={[required, maxDateToday, min18Year]}
                                />
                            </div>
                        </div>

                        <QuestionLabel className={'f-16 mt-4'}>Pays de naissance{!isAvenant && <sup className={'text-danger'}>*</sup>}</QuestionLabel>
                        <div className={'row'}>
                            <div className={'col-12 col-md-6 mt-3 mt-md-4'}>
                                <Field
                                    name={'DemandeTarif[ListePersonnes][0][BirthCountry]'}
                                    component={Autocomplete}
                                    data={countries}
                                    validate={!isAvenant ? required : null}
                                    disabled={false}
                                />
                            </div>
                        </div>

                        <QuestionLabel className={'f-16 mt-4'}>Ville de naissance{!isAvenant && <sup className={'text-danger'}>*</sup>}</QuestionLabel>
                        <div className={'row'}>
                            <div className={'col-12 col-md-6 mt-3 mt-md-4'}>
                                {
                                    !this.state.capbirthIsDown && birthCountry === 'FRA' ?
                                        <Field
                                            name={'DemandeTarif[ListePersonnes][0][BirthCity]'}
                                            component={AutoCompleteBirth}
                                            setErrorLoadingCountries={setErrorLoadingCountries}
                                            setCapBirthIsDown={(value) => this.setState({capbirthIsDown: value})}
                                            validate={!isAvenant ? required : null}
                                            country={birthCountry}
                                            onChangeCityFunction={(value, listVilles) => {
                                                changeValue(`DemandeTarif[ListePersonnes][0][BirthDepartment]`, null)
                                                if (value) {
                                                    const villeSelected = _.find(listVilles, ['value', value]);
                                                    if (villeSelected?.department) {
                                                        changeValue(`DemandeTarif[ListePersonnes][0][BirthDepartment]`, villeSelected.department)
                                                    }
                                                }
                                            }}
                                        /> :  <Field
                                            component={Text}
                                            name={'DemandeTarif[ListePersonnes][0][BirthCity]'}
                                            normalize={v => v.toUpperCase()}
                                            validate={[required]}
                                        />
                                }
                            </div>
                        </div>


                        <QuestionLabel className={'f-16 mt-4'}>Code postal et ville<sup className={'text-danger'}>*</sup></QuestionLabel>
                        <div className={'row'}>
                            <div className={'col-12 col-md-6 mt-3 mt-md-4'}>
                                <PostalCodeCity
                                    id={'lieuStationnement'}
                                    nameVille={'DemandeTarif[ListePersonnes][0][Ville]'}
                                    nameCodePostal={'DemandeTarif[ListePersonnes][0][CP]'}
                                    cityPlaceholder={'Ville'}
                                    postalPlaceholder={'Code postal'}
                                    formName={'devis'}
                                    validate={[required, excludeCodePostal]}
                                    defaultValue={postalCodeCity}
                                    changeValue={changeValue}
                                    onChange={(e) => _.isEmpty(e) ? changeValue('postalCodeCity-lieuStationnement', false) : changeValue('postalCodeCity-lieuStationnement', true)}
                                />
                                <p className={'m-0 text-grey'}><small>Ex: 75011 PARIS</small></p>
                            </div>
                        </div>

                        <CAPAdresseTrottinetteVae changeValue={changeValue} setHasSearchAddress={setHasSearchAddress} data={data} />

                        <QuestionLabel className={'f-16 mt-4'}>N° de téléphone<sup className={'text-danger'}>*</sup></QuestionLabel>
                        <div className={'row'}>
                            <div className={'col-12 col-md-6 mt-3 mt-md-4'}>
                                <Field
                                    name={'DemandeTarif[ListePersonnes][0][TelPortable]'}
                                    component={Text}
                                    validate={[required, validatePhone]}
                                    type={'tel'}
                                    icon={'phone'}
                                    iconSize={30}
                                    normalize={normalizeTel}
                                    onChange={e => changeValue('DemandeContrat[SignaturePhone]', normalizeTel(e.target.value) )}
                                />
                            </div>
                        </div>
                        <p className={'m-0 text-grey'}><small>{contenuText.demarcheTel}</small></p>

                        <div className={"d-none"}>
                        <Field component={Text} name={'emailOnError'} type={"hidden"} validate={onError} />
                        </div>

                        <QuestionLabel className={'f-16 mt-4'}>Adresse email<sup className={'text-danger'}>*</sup></QuestionLabel>
                        <div className={'row'}>
                            <div className={'col-12 col-md-6 mt-3 mt-md-4'}>
                                <Field
                                    name={'DemandeTarif[ListePersonnes][0][Email]'}
                                    component={Text}
                                    icon={'envelope'}
                                    validate={[required, validateEmail]}
                                    iconSize={18}
                                    onChange={(e) => {changeValue('emailOnError', e.target.value && emailValidation && e.target.value !== emailValidation ? 'onError' : null)}}
                                />
                            </div>
                        </div>

                        <QuestionLabel className={'f-16 mt-4'}>Confirmation de l'adresse email<sup className={'text-danger'}>*</sup></QuestionLabel>
                        <div className={'row'}>
                            <div className={'col-12 col-md-6 mt-3 mt-md-4'}>
                                <Field
                                    name={'EmailValidation'}
                                    component={Text}
                                    icon={'envelope'}
                                    validate={[required, validateEmail]}
                                    iconSize={18}
                                    onChange={(e) => {changeValue('emailOnError', email && e.target.value && email !== e.target.value ? 'onError' : null)}}
                                />
                            </div>
                        </div>

                        { source === 'site' &&
                        <p className={'m-0 text-grey'}>
                            <small>En nous communiquant votre adresse e-mail, vous acceptez que la gestion et l’exécution
                                de votre contrat soient réalisées par voie dématérialisée : vous pourrez y mettre un terme
                                à tout moment et sans frais en vous rendant sur votre Espace Assuré.</small>
                        </p>
                        }
                        { emailOnError && emailOnError === 'onError' && <div className="alert alert-danger f-14 mt-4" role="alert">Les deux adresses emails doivent être identiques.</div>}

                        { source === 'courtier' &&
                        <Fragment>
                            <QuestionLabel className={'f-16 mt-4'}>Adresse email du courtier</QuestionLabel>
                            <div className={'row'}>
                                <div className={'col-12 col-md-6 mt-3 mt-md-4'}>
                                    <Field
                                        name={'DemandeDevis[EmailCourtier]'}
                                        component={Text}
                                        icon={'envelope'}
                                        validate={validateEmail}
                                        iconSize={18}
                                    />
                                </div>
                            </div>
                            <p className={'m-0 text-grey'}>
                                <small>Vous recevrez l'ensemble des documents récapitulatif au contrat d'assurance de votre client par email.</small>
                            </p>
                        </Fragment>
                        }

                        {formule === 'F2' && <>
                            <Title icon={'insurance'} className={'mt-5'} dangerouslySetInnerHTML={{__html: 'Les enfants assurés' }}/>
                            <QuestionLabel className={'f-16 mt-4'}>{contenuText.title_enfant}</QuestionLabel>
                            <div className={'content-radio'}>
                                <Field
                                    name={'DemandeTarif[ListePersonnes][0][DeclaASSR2]'}
                                    component={Radio}
                                    className={"small"}
                                    type={'radio'}
                                    label={contenuText.txt_btn_question_enfant_oui}
                                    value={'true'}
                                />
                                <span style={{margin: 5}}/>
                                <Field
                                    name={'DemandeTarif[ListePersonnes][0][DeclaASSR2]'}
                                    component={Radio}
                                    className={"small"}
                                    type={'radio'}
                                    label={contenuText.txt_btn_question_enfant_non}
                                    value={'false'}
                                />
                            </div>
                        </>}

                        <ChampsObligatoires/>

                        <div className={'bg-grey p-4 rounded text-left mt-5'}>
                            <p className={'f-16 fw-bold text-primary-dark'}>Vos données personnelles</p>
                            <p className={'f-14 mb-1'}>Les données personnelles que vous renseignez dans ce formulaire sont nécessaires pour établir une proposition,
                                pour la conclusion et l'exécution du contrat d’assurance deux roues et la prise en charge des garanties qui y sont associées.</p>
                            {this.state.showMore ?
                                <Fragment>
                                    <blockquote className={'f-14'}>
                                        Les données traitées font l’objet d’un traitement automatisé y compris le
                                        profilage, donnant lieu à l’établissement d’un tarif adapté à votre situation.
                                        Vos données peuvent être utilisées à des fins statistiques en vue d’alimenter et
                                        d’affiner notre outil de tarification, y compris dans le cas où vous ne donnez
                                        pas suite à votre proposition.<br/><br/>
                                        En collectant vos données de proposition {whitelabelName} est susceptible de vous adresser
                                        des relances par mail afin de finaliser votre contrat. A cet effet, {whitelabelName} s’engage
                                        à prendre toutes les mesures de sécurité nécessaires afin de préserver
                                        la confidentialité de vos données en respectant les dispositions du Règlement
                                        Général sur la Protection des Données à Caractère Personnel.<br/><br/>
                                        Dans le cadre du Règlement Général sur la Protection des Données à Caractère
                                        Personnel, vous pouvez à tout moment faire valoir vos droits en contactant
                                        l’adresse suivante : {emailDPOWhitelabel}<br/><br/>
                                        Pour plus d’informations sur les traitements dont font l’objet vos données
                                        personnelles, vous pouvez à tout moment consulter notre politique de
                                        confidentialité sur notre site internet {urlSiteWhitelabel ?
                                            urlSiteWhitelabel
                                            : (gestionnaireIsAMO ?
                                                'https://www.april-moto.com/'
                                                : 'https://amgestionassurance.com/'
                                            )
                                        }
                                    </blockquote>
                                    <p className={'fw-bold'} onClick={() => this.setState({showMore: !this.state.showMore})}>Voir moins</p>
                                </Fragment>
                                : <p className={'f-14 fw-bolder'} onClick={() => this.setState({showMore: !this.state.showMore})}>En savoir plus</p>
                            }
                        </div>

                        <div className={'bg-grey p-4 rounded text-left'}>
                            <p className={'f-16 fw-bold text-primary-dark'}>Véhicule utilisé : Trottinette électrique.</p>
                            <p className={'f-14 mb-1'}>Véhicule électrique sans siège constitué d'une plateforme de deux roues et d’une planche sur laquelle le conducteur conduit debout à l’aide d’un guidon. Sa vitesse ne dépasse pas 25km/h. La propulsion est totalement ou partiellement assurée par un moteur électrique, à partir de sources d'alimentation autonomes.</p>
                        </div>

                        {libelle === 'Solo' && <div className={'bg-grey p-4 rounded text-left'}>
                            <p className={'f-16 fw-bold text-primary-dark'}>Clause d'usage Formule Solo</p>
                            <p className={'f-14 mb-1'}>Le Souscripteur est assuré pour des déplacements privés et professionnels en trottinette électrique, qui ne sert en aucun cas à des tournées régulières (livraisons, dépôts, clientèle, agences, succursales ou chantiers), au transport à titre onéreux de marchandises ou de voyageurs). Les garanties souscrites sont également acquises pour l’usage des trottinettes électriques proposées en libre-service à condition de respecter les critères et recommandations du loueur dont l’âge minimal autorisé. Le transport de passager n’est pas couvert.</p>
                        </div>}

                        {libelle === 'Famille' && <div className={'bg-grey p-4 rounded text-left'}>
                            <p className={'f-16 fw-bold text-primary-dark'}>Clause d'usage Formule Famille</p>
                            <p className={'f-14 mb-1'}>Le souscripteur, son conjoint, partenaire de PACS ou concubin et ses enfants titulaires à minima d’une Attestation Scolaire de Sécurité Routière de Niveau 2 (ASSR2) sont assurés pour des déplacements privés et professionnels en trottinette électrique, qui ne sert en aucun cas à des tournées régulières (livraisons, dépôts, clientèle, agences, succursales ou chantiers), au transport à titre onéreux de marchandises ou de voyageurs. Les garanties souscrites sont également acquises pour l’usage des trottinettes électriques proposées en libre-service à condition de respecter les critères et recommandations du loueur dont l’âge minimal autorisé. Le transport de passager n’est pas couvert.</p>
                        </div>}

                        <div className={'row'}>
                            <div className={'col text-center'}>
                                <div className={'mt-5  text-center'}>
                                    {loading
                                        ? <><SmallSpinner/></>
                                        :
                                        <>
                                                <button
                                                    className={`btn-arrow btn btn-primary medium-rounded`}
                                                    onClick={handleSubmit(values => postSubmit(values, 'recapitulatif'))}
                                                    disabled={invalid || submitting || loading}>Continuer
                                                </button>

                                                <button
                                                    className={`btn btn-link fw-bold`}
                                                    onClick={handleSubmit(values => postSubmit(values, 'proposition'))}
                                                    disabled={invalid || submitting || loading}
                                                >
                                                    {contenuText.text_proposition}
                                                </button>
                                            </>
                                    }
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className={'col-lg-4 d-lg-inline-block'}>
                        <BulleAide etape={'1'} contenuText={contenuText} formule={formule}/>
                    </div>
                </form>

            </div>
        );
    }
}

export default Informations;
