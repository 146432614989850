export const Theme = {
    color: {
        primary: {
            color1: '#003f63',
            color2: '#faa000',
            color3: '#cbd400',
            color4: '#e6e6e6',
        },
        secondary: {
            color1: '#00334f',
            color2: '#ffb022',
            color3: '#b8c000'
        },
        formulaire: {
            color1: '#81961f',
            color2: '#8aa2ac',
            color3: '#e7f0f4',
            color4: '#f3f9fc',
            color5: '#d8e3e9', // grey blue - disabled wizard
            color6: '#84969e' // grey used on mobile
        },
        bulleAide: {
            color1: "#5b6e78", // grey used for basic text
            color2: "#96b1be" // Grey used for icons
        },
        error: {
            color1: '#f0515b',
            color2: '#faf0e6',
            color3: '#e7811a',
            color4: '#fbcfd2'
        },
        success: {
            color1: '#cbd400'
        }
    },
    font: {
        primary: "'Poppins', Arial, sans-serif",
        secondary: "'Poppins', Arial, sans-serif" // Acumin Pro alternative
    }
};

// Extra small devices (portrait phones, less than 576px)
export const ExtraSmallDevices = 'max-width: 575.98px'

// Small devices (landscape phones, 576px and up)
export const minSmallDevices = 'min-width: 576px'
export const maxSmallDevices = 'max-width: 768px'
export const SmallDevices = `(${minSmallDevices}) and (${maxSmallDevices})`

// Medium devices (tablets, 768px and up)
export const minMediumDevices = 'min-width: 768px'
export const maxMediumDevices = 'max-width: 991.98px'
export const MediumDevices = `(${minMediumDevices}) and (${maxMediumDevices})`

// Large devices (desktops, 992px and up)
export const minLargeDevices = 'min-width: 992px'
export const maxLargeDevices = 'max-width: 1199.98px'
export const LargeDevices = `(${minLargeDevices}) and (${maxLargeDevices})`

// Extra large devices (large desktops, 1200px and up)
export const ExtraLargeDevices = 'min-width: 1200px'
